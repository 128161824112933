import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, ListGroup, Container} from 'react-bootstrap';
import "../Analytics.css";
import heedIcon from "../heed.png";
import blueIcon from "../blue_logo.png";
import retailerIcon from "../retailer.jpeg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faChartSimple, faBoxesStacked, faUser, faBars, faGear } from '@fortawesome/free-solid-svg-icons'
import CircleLoader from "../Components/CircleLoader";
import Chart from "../Components/Chart/Chart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

function Analytics({}) {
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    // DATA
    const [timeframe, setTimeframe] = useState("30");
    const [data, setData] = useState(null);

    const [total, setTotal] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);

    const [flowerOrders, setFlowerOrders] = useState(0);
    const [flowerVolume, setFlowerVolume] = useState(0);

    const [prOrders, setPrOrders] = useState(0);
    const [prVolume, setPrVolume] = useState(0);

    const [vapeOrders, setVapeOrders] = useState(0);
    const [vapeVolume, setVapeVolume] = useState(0);

    const [concentrateOrders, setConcentrateOrders] = useState(0);
    const [concentrateVolume, setConcentrateVolume] = useState(0);

    const [edibleOrders, setEdibleOrders] = useState(0);
    const [edibleVolume, setEdibleVolume] = useState(0);

    const [topicalOrders, setTopicalOrders] = useState(0);
    const [topicalVolume, setTopicalVolume] = useState(0);

    const [otherOrders, setOtherOrders] = useState(0);
    const [otherVolume, setOtherVolume] = useState(0);

    const [totalProducts, setTotalProducts] = useState(0);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
  );

  const options = {
      responsive: true,
      scales: {
          x: {
              grid: {
              drawBorder: false,
              display: false
              }
          },
          y: {
              grid: {
              display: false,
              drawBorder: false,
              },
              ticks: {
              beginAtZero: true,
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                  return '$' + value.toFixed(2);
              }
          }
          }
      },
      plugins: {
          legend: {
          position: 'bottom',
          },
          title: {
          display: false,
          text: 'Chart.js Line Chart',
          }          
      },
  };



    useEffect(() => {
      setData(null);
      var endDate = new Date(Date.now())
      endDate = endDate.setDate(endDate.getDate() + 1);
      endDate = new Date(endDate).toLocaleDateString('en-CA');
      var startDate = new Date(Date.now());

      if (timeframe == "365") {
        startDate = new Date(startDate.getFullYear(), 0, 2).toLocaleDateString('en-CA');
      }

      else if (timeframe == "90") {
        
        startDate = new Date(startDate.setMonth(startDate.getMonth() - 2));
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 2).toLocaleDateString('en-CA');
      } 
      
      else if (timeframe == "30") {
        // Set start date as first date of the month
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 2).toLocaleDateString('en-CA');
      } 
      
      else if (timeframe == "7") {
          let d = new Date();
          var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6:2); // adjust when day is sunday
          startDate = new Date(d.setDate(diff)).toLocaleDateString('en-ca');

          console.log(startDate, "yooo")

          // startDate = new Date(startDate.setDate(startDate.getDate() - 5)).toLocaleDateString('en-CA');
      }

      else if (timeframe == "1") {
        startDate = new Date(startDate.setDate(startDate.getDate())).toLocaleDateString('en-CA');
      }



      getAnalytics(startDate, endDate);
      
    }, [timeframe]);


    const getAnalytics = (startDate, endDate) => {
      let token = localStorage.getItem("@token");
      let retailerId = localStorage.getItem("@retailer");
      if (!token || !retailerId) {
          return window.location.href = "/login";
      }
      fetch( `${hostname}/api/analytics/${retailerId}/sales?startDate=${startDate}&endDate=${endDate}`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
      .then((response) => response.json())
      .then(async (response) => {
          if (response.success) {
            setTotalOrders(response.data.length);
            var totalLocal = 0;
            // category flags
            var flower = 0;
            var flowerValue = 0;
            var pr = 0;
            var prValue = 0;
            var edibles = 0;
            var ediblesVaue = 0;
            var vapes = 0;
            var vapesVaue = 0;
            var concentrates = 0;
            var concentratesValue = 0;
            var topicals = 0;
            var topicalsVaue = 0;
            var other = 0;
            var otherValue = 0;
            var totalProductsLocal = 0;
            for (var i = 0; i < response.data.length; i++) {
              var order = response.data[i];
              if (order.status.message != "Cancelled" && order.settlement && order.settlement.amount) {
                var chargeAmount = order.settlement.amount;
                totalLocal+=chargeAmount;
                var products = order.products;
                for (var j = 0; j < products.length; j++) {
                  var product = products[j];
                  var quantity = product.ATCQUANTITY;
                  var price = product.Prices[0];
                  var category = product.type;
                  totalProductsLocal+=quantity;
                  if (category == "Flower") {
                    flower += quantity;
                    flowerValue +=  price;
                  }
                  else if (category == "Edible") {
                    edibles += quantity;
                    ediblesVaue += price;
                  }
                  else if (category == "Vaporizers") {
                    vapes += quantity;
                    vapesVaue += price;
                  }
                  else if (category == "Pre-Rolls") {
                    pr += quantity;
                    prValue += price;
                  }
                  else if (category == "Concentrate") {
                    concentrates += quantity;
                    concentratesValue += price;
                  }
                  else if (category == "Topical") {
                    topicals += quantity;
                    topicalsVaue += price;
                  } else {
                    other += quantity;
                    otherValue += price;
                  }
                }
              }
            }

            setFlowerOrders(flower);
            setFlowerVolume(flowerValue);
            setPrOrders(pr);
            setPrVolume(prValue);
            setEdibleOrders(edibles);
            setEdibleVolume(ediblesVaue);
            setVapeOrders(vapes);
            setVapeVolume(vapesVaue);
            setConcentrateOrders(concentrates);
            setConcentrateVolume(concentratesValue);
            setTopicalOrders(topicals);
            setTopicalVolume(topicalsVaue);
            setOtherOrders(other);
            setOtherVolume(otherValue);
            setTotalProducts(totalProductsLocal);
            setTotal(totalLocal/100);
            buildAnalytics(startDate, endDate, response.data);


          } else {
              if (response.error == "not logged in") {
                  localStorage.removeItem("@token");
                  window.location.href = "/login";
              }
          }
      })
      .catch((err) => {console.log(err);
      }); 
    }

    const buildAnalytics = (startDate, endDate, orders) => {


      const x = [];
      const y = [];
      let currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        x.push(new Date(currentDate).toLocaleDateString());
        y.push({date: new Date(currentDate).toLocaleDateString(), data: []});
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      for (var i = 0; i < orders.length; i++) {
        var orderDate = new Date (orders[i].dateCreatedUtc).toLocaleDateString();
        for (var j = 0; j < y.length; j++) {
            var dataDate = new Date(y[j].date).toLocaleDateString();
            if (orderDate == dataDate) {

              var products = orders[i].products;
              var orderPrice = 0;
              for (var k = 0; k < products.length; k++) {
                var product = products[k];
                var quantity = product.ATCQUANTITY;
                var price = product.Prices[0];
                orderPrice+=(price*quantity);
              }
              
              y[j].data.push(orderPrice.toFixed(2));
            }
        }
      }

      var values = [];
      for (var i = 0; i < y.length; i++) {
        var value = 0;
        for (var j = 0; j < y[i].data.length; j++) {
          value+=parseFloat(y[i].data[j]);
        }
        values.push(value.toFixed(2));
      }


      let dataLocal = {
          labels: x,
          datasets: [
            {
              label: 'Online',
              data: values,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              fill:true
            },
          ],
        };
        setData(dataLocal);
    }
  

return (
    <div className="App">

        <Navbar className="Mobile-Nav navbar-light" expand="lg">
        <Container>
            <Navbar.Brand href="#">
                <img src={blueIcon} width="50" height="50" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link href="/">Analytics</Nav.Link>
                    <Nav.Link href="/orders">Orders</Nav.Link>
                    <Nav.Link href="/customers">Customers</Nav.Link>
                    <Nav.Link href="/menu">Menu</Nav.Link>
                    <Nav.Link href="/settings">Settings</Nav.Link>
                    <Nav.Link href="#">Support</Nav.Link>
                    <Nav.Link onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} href="#">Logout</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <div className='Sidebar'>
          

          {
            localStorage.getItem("@image") ? 
            (
              <img className="Retailer" src={localStorage.getItem("@image")}></img>
            ) : null
          }
        {localStorage.getItem("@location") ? ( 
          <h5 style={{fontSize:12, fontWeight:"bold", marginTop:15}}>{localStorage.getItem("@location")}</h5>
        ) : null}
        


        <div className="Sidebar-Middle">
             <ListGroup as="ul">
                 <ListGroup.Item active onClick={() => {window.location.href = '/'}} as="li" style={{cursor: 'pointer'}} >
                   <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faChartSimple} />
                    Analytics
                </ListGroup.Item>

                <ListGroup.Item onClick={() => {window.location.href = '/orders'}} as="li" style={{cursor: 'pointer'}} >
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBoxesStacked} />
                    Orders
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/customers'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faUser} />
                    Customers
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/menu'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBars} />
                    Menu
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faGear} />
                    Settings
                </ListGroup.Item>
            </ListGroup>
        </div>

        <div className="Sidebar-Bottom">

            <div onClick={() => {window.location.href = "/location"}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
              <FontAwesomeIcon style={{cursor:"pointer", fontSize:36, color:"#8bb8fc"}} icon={faDoorOpen} />
              <p style={{color:"#8bb8fc", marginTop:5}}>Switch Location</p>
            </div>

            {/* <img className='Logo' src={heedIcon}></img> */}

            <div>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Support
                </ListGroup.Item>
        
                <ListGroup.Item onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Logout
               </ListGroup.Item>
            </div>

        </div>



        </div>

        <div className="App-Body">
            <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                <h1 style={{fontWeight:"bold"}}>Analytics</h1>
            </div>
      
                <div>
                  {/* Time filters */}

                  <div className="Filter-Container">
                    <select value={timeframe} onChange={(e) => {setTimeframe(e.target.value)}} style={{fontSize:14, marginRight:15}}>
                      <option value="1">24h</option> 
                      <option value="7">Week to date</option> 
                      <option value="30">Month to date</option>
                      <option value="90">Quarter to date</option>
                      <option value="365">Year to date</option>
                    </select>
                    
                    {/* <h5 style={{marginTop: 5, color: "rgba(172, 172, 172, 1)", fontWeight:"bold", fontSize: 14}}>Apr 29 - May 06</h5> */}
                  </div>

                  {/* Chart Container */}
                  <div className="Chart-Container">
                      {/* Labels */}
                      <div className="Chart-Header">
                        <h1>Sales Overview</h1>

                        <div style={{display:"flex", flexDirection:"row"}}>

                            <div style={{marginRight:60, display: "flex", flexDirection:"column"}}>
                              <h5  style={{color: "rgba(172, 172, 172, 1)", fontWeight:"bold", fontSize: 16}}>Total Sales</h5>
                              <h2>${total}</h2>
                            </div>

                            <div style={{display: "flex", flexDirection:"column"}}>
                              <h5  style={{color: "rgba(172, 172, 172, 1)", fontWeight:"bold", fontSize: 16}}>Total Orders</h5>
                              <h2>{totalOrders}</h2>
                            </div>


                        </div>
                      </div>
    
                      {/* Chart */}

                      {
                        data === null ? (
                          <CircleLoader></CircleLoader>
                        ) : (
                          // <Chart data={data}></Chart> 
                          <Line redraw options={options} data={data} />                         
                        )
                      }

                      {/* Legend */}

                      <div className="Sales-Breakdown">
                        <h5 style={{color: "rgba(172, 172, 172, 1)", fontWeight:"bold", fontSize: 14, marginBottom:30}}>Category Breakdown</h5>
                        
                        <div>

                          <div style={{marginBottom:15}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Flower</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(flowerVolume).toFixed(0)}</h5>
                            </div>

                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(flowerOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                           <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Vapes</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(vapeVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(vapeOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Pre-Rolls</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(prVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(prOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Edibles</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(edibleVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(edibleOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                          <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Concentrates</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(concentrateVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(concentrateOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Topicals</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(topicalVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(topicalOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                          <div style={{marginBottom:15}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>Other</h5>
                              <h5 style={{color: "rgba(102, 102, 102, 1)", fontWeight:"bold", fontSize: 16}}>${(otherVolume).toFixed(0)}</h5>
                            </div>
                            <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{width:`${(otherOrders/totalProducts)*100}%`}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>

                        </div>

                      </div>
                  </div>

              </div>
             
            

        </div>
    
    
        </div>
      );
}

export default Analytics;