import React, { useEffect, useState, useRef } from "react";
import Multiselect from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button} from 'react-bootstrap';
import { useAlert } from 'react-alert'
import Select from 'react-draggable-multi-select';

const Catalogue = ({ fetchRetailer, index, data, productsMultiSelect }) => {
    const [title, setTitle] = useState(data.title);
    const [products, setProducts] = useState([]);
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const alert = useAlert()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        if (data.products) {
            console.log(data.products[0]);
            var productValues = data.products.map(product => (
                {
                  label: product.name,
                  value: product.id
                } 
            ))
            setProducts(productValues);
        }
    }, [data]);

    const saveShelf = () => {

        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }

        // Save changes for index
        if (!title || !products || !products.length > 0) {
            return alert.show("Please enter all fields");
        }
        var postProducts = products.map(product => (
            {
              name: product.label,
              id: product.value
            } 
        ));
        const form = {
            index: index,
            title: title,
            products: postProducts
        }
        fetch( `${hostname}/api/retailer/${retailerId}/menu/catalogue`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "PUT", body: JSON.stringify(form), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                handleClose();
                fetchRetailer();
                alert.show("Updated catalogue shelf!");
            }
        })
        .catch((err) => {console.log(err);
        });
    }

    const deleteShelf = () => {
        var result = window.confirm("Are you sure you want to delete this shelf?");
        if (result) {

            let token = localStorage.getItem("@token");
            let retailerId = localStorage.getItem("@retailer");
            if (!token || !retailerId) {
                return window.location.href = "/login";
            }

            // Delete index
            const form = {
                index: index
            }
            fetch( `${hostname}/api/retailer/${retailerId}/menu/catalogue`, {    headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json' }, method: "DELETE", body: JSON.stringify(form), crossDomain:true })
            .then((response) => response.json())
            .then(async (response) => {
                if (response.success) {
                    handleClose();
                    fetchRetailer();
                    alert.show("Deleted catalogue shelf!");
                }
            })
            .catch((err) => {console.log(err);
            });
        }
    }

    return (
        <div className="Shelve">    
            
            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <h5 style={{margin:0, marginTop:0, marginBottom:0, marginRight:15, fontSize:16}}>{title}</h5>
                <span>|</span>
                <p style={{margin:0, marginLeft:15, marginRight:10, fontSize:16}}>{products.length} products</p>
                <a onClick={handleShow} style={{cursor:"pointer", color:"#5096FF", margin:0, fontSize:14}}>edit</a>
            </div>

            <div style={{cursor:"pointer"}}>
                <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
            </div>


            <Modal dialogClassName="modal-90w" fullscreen={"sm-down"} show={show} onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                <Modal.Title>Edit Catalogue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:0}}>
                        <label style={{marginBottom:15}}>Title</label>
                        <input style={{width:"100%"}} placeholder="Staff Picks" onChange={(e) => {setTitle(e.target.value)}} value={title}></input>
                    </div>
                    <div style={{marginTop:30}} className="">
                        <label style={{marginBottom:15}}>Products</label>
                        {/* <Multiselect
                            placeholder="Search for Product(s)"
                            style={{marginTop:15}}
                            options={productsMultiSelect} 
                            selectedValues={products}
                            onSelect={(e) => {
                                setProducts(e);
                            }}
                            onRemove={(e) => {
                                setProducts(e);
                            }}
                            displayValue="name"
                        /> */}

                        <Select
                            style={{marginTop:15}}
                            value={products}
                            onChange={(e => {
                                // setUpsells(e);
                                setProducts(e);
                            })}
                            options={productsMultiSelect}
                            isMulti={true}
                            closeMenuOnSelect={false}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button style={{ backgroundColor: "#5096FF"}} onClick={() => {saveShelf()}} variant="primary">
                    Save Shelf
                </Button>
                <Button onClick={() => {deleteShelf()}} variant="secondary">
                    Delete Shelf
                </Button>
                </Modal.Footer>
            </Modal>

            {/* <div style={{marginTop:0}}className="Form-Input">
                <label style={{marginBottom:15}}>Title</label>
                <input placeholder="Staff Picks" onChange={(e) => {setTitle(e.target.value)}} value={title}></input>
            </div>
            
            

            <div className="Form-Input">
                <label style={{marginBottom:15}}>Products</label>
                <Multiselect
                    placeholder="Search for Product(s)"
                    style={{marginTop:15}}
                    options={productsMultiSelect} 
                    selectedValues={products}
                    onSelect={(e) => {
                        setProducts(e);
                    }}
                    onRemove={(e) => {
                        setProducts(e);
                    }}
                    displayValue="name"
                />
            </div>

            <div style={{display:'flex', flexDirection:'row'}} className="Form-Input">
                <button onClick={() => {
                    saveShelf();
                }} className="Save">Save Shelf</button>

                <button style={{marginLeft:15, backgroundColor:'rgba(184, 184, 184, 1)'}} onClick={() => {
                    deleteShelf();
                }} className="Save">Delete</button>
            </div> */}
            


        </div>
    )
}

export default Catalogue