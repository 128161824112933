import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, ListGroup, Container} from 'react-bootstrap';
import MaterialTable from "material-table";
import { CsvBuilder } from 'filefy';

import "../Table.css";
import heedIcon from "../heed.png";
import blueIcon from "../blue_logo.png";
import retailerIcon from "../retailer.jpeg";
import CircleLoader from "../Components/CircleLoader";

import alertSound from "../alert.mp3";
import useSound from 'use-sound';
import { useAlert } from 'react-alert'

// import bootbox from "bootbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faCaretLeft, faChartSimple, faBoxesStacked, faUser, faBars, faGear } from '@fortawesome/free-solid-svg-icons'

function Orders({}) {
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const [pastOrders, setPastOrders] = useState(false);
    const [newOrder, setNewOrder] = useState(false);
    const [orders, setOrders] = useState([]);
    const [ordersFiltered, setOrdersFiltered] = useState([]);
    var orderIds = [];
    const [play, { stop }] = useSound(alertSound);
    const alert = useAlert()

    const [openOrder, setOpenOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const fetchOrders = (firstRun) => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/orders/${retailerId}`, {    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                for (var i = 0; i < response.data.length; i++) {
                    if (!orderIds.includes(response.data[i].id)) {
                        orderIds = await response.data.map(order => order.id);
                        setOrders(response.data);
                        if (!firstRun) {
                            setNewOrder(true);
                        } else {
                            setLoading(false);
                        }
                        break;
                    }
                }       
                setTimeout(fetchOrders, 3000, false);
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err);
            setTimeout(fetchOrders, 3000, false);
        });  
    }

    const dismissOrder = () => {
        stop();
        setNewOrder(false);
    }

    const checkNewOrder = () => {
        if (newOrder) {
            play();
            setTimeout(checkNewOrder, 1500);
        } else {
            stop();
            return;
        }
    }
    
    useEffect(() => {
        if (newOrder) {
            alert.show('Theres been an order!' , {
                timeout: 0, // custom timeout just for this one alert
                type: 'success',
                onClose: () => {
                  dismissOrder();
                }
            });
        }
        checkNewOrder();
    }, [newOrder]);
    

    useEffect(() => {
        // Filter old orders
        var filtered = [];
        for (var i = 0; i < orders.length; i++) {
            var order = orders[i];
            console.log(order.status);
            if (pastOrders) {
                if (order.status.complete) {
                    filtered.push(order)
                }
            } else {
                if (!order.status.complete) {
                    filtered.push(order)
                }
            }

        }

        console.log(filtered.length)

        setOrdersFiltered(filtered);
    }, [orders]);

    useEffect(() => {
        fetchOrders(true);
        setTimeout(() => {
            window.location.reload();
        }, 1800000);
    }, []);


    useEffect(() => {
         // Filter old orders
         var filtered = [];
         for (var i = 0; i < orders.length; i++) {
             var order = orders[i];
             if (pastOrders) {
                 if (order.status.complete) {
                     filtered.push(order)
                 }
             } else {
                if (!order.status.complete) { 
                    filtered.push(order)
                 }
             }
 
         }
  
         setOrdersFiltered(filtered);
    }, [pastOrders]);


    useEffect(() => {
        console.log(openOrder);
    }, [openOrder]);


return (
    <div className="App">



        <Navbar className="Mobile-Nav navbar-light" expand="lg">
        <Container>
            <Navbar.Brand href="#">
                <img src={blueIcon} width="50" height="50" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link href="/">Analytics</Nav.Link>
                    <Nav.Link href="/orders">Orders</Nav.Link>
                    <Nav.Link href="/customers">Customers</Nav.Link>
                    <Nav.Link href="/menu">Menu</Nav.Link>
                    <Nav.Link href="/settings">Settings</Nav.Link>
                    <Nav.Link href="#">Support</Nav.Link>
                    <Nav.Link onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} href="#">Logout</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <div className='Sidebar'>

        {
            localStorage.getItem("@image") ? 
            (
              <img className="Retailer" src={localStorage.getItem("@image")}></img>
            ) : null
          }
        {localStorage.getItem("@location") ? ( 
          <h5 style={{fontSize:12, fontWeight:"bold", marginTop:15}}>{localStorage.getItem("@location")}</h5>
        ) : null}
        
        <div className="Sidebar-Middle">
             <ListGroup as="ul">
                 <ListGroup.Item onClick={() => {window.location.href = '/'}} as="li" style={{cursor: 'pointer'}} >
                   <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faChartSimple} />
                    Analytics
                </ListGroup.Item>

                <ListGroup.Item active onClick={() => {window.location.href = '/orders'}} as="li" style={{cursor: 'pointer'}} >
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBoxesStacked} />
                    Orders
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/customers'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faUser} />
                    Customers
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/menu'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBars} />
                    Menu
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faGear} />
                    Settings
                </ListGroup.Item>
            </ListGroup>
        </div>

        <div className="Sidebar-Bottom">
           <div onClick={() => {window.location.href = "/location"}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
              <FontAwesomeIcon style={{cursor:"pointer", fontSize:36, color:"#8bb8fc"}} icon={faDoorOpen} />
              <p style={{color:"#8bb8fc", marginTop:5}}>Switch Location</p>
            </div>
    
            {/* <img className='Logo' src={heedIcon}></img> */}
            <div>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Support
                </ListGroup.Item>
        
                <ListGroup.Item onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Logout
               </ListGroup.Item>
            </div>

        </div>



        </div>


        {
            openOrder === null ? (
                <div className="App-Body">
                    <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                        <h1>Orders</h1>
                    </div>
            
                    <div className="Body-Header">
                        <a href="#" onClick={() => {setPastOrders(false);}}  className={!pastOrders ? "active-header" : ""} style={{marginRight:15}}>
                        Current
                        </a>
            
                        <a href="#" onClick={() => {setPastOrders(true);}} className={pastOrders ? "active-header" : ""} style={{marginRight:15}}>
                        Past
                        </a>
            
                        {/* <a style={{}}>
                        Cancelled
                        </a> */}
            
                    </div>
                    
                            <div>
                                <div className="helper">
                                    <p>Confirm order will accept the charge and push the order to the POS, and cancel order will refund the customer or drop the pending charge.</p>
                                </div>
                            {
                                loading === false ? (
                                    <MaterialTable
                                        options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            sorting: true,
                                            selection: false,
                                            editable: false,
                                            filtering:false,
                                            pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100, 150],
                                            exportCsv: async (cols, data) => {
                                                const columnTitles = cols
                                                .map(columnDef => columnDef.title);
                                            
                                                const csvData = data.map(rowData => {
                                                    // console.log(rowData);
                                                    return cols.map(columnDef => {
                                                        // rowData[columnDef.field]
                                                        if (columnDef.title == "Status") {
                                                            return rowData.status.message;
                                                        }
                                                        else if (columnDef.title == "Receipt #") {
                                                            return rowData.receiptNumber;
                                                        }
                                                        else if (columnDef.title == "Customer") {
                                                            return rowData.firstName + " " + rowData.lastName;
                                                        }
                                                        else if (columnDef.title == "Card") {
                                                            return !rowData.card ? "n/a" : rowData.card
                                                        }
                                                        else if (columnDef.title == "Date") {
                                                            return new Date(Date.parse(rowData.dateCreatedUtc)).toLocaleDateString('en-US')
                                                        }
                                                        else if (columnDef.title == "Time") {
                                                            return new Date(Date.parse(rowData.dateCreatedUtc)).toLocaleTimeString('en-US')
                                                        }
                                                        else if (columnDef.title == "Price") {
                                                            var priceAmount = 0;
                                                            if (Array.isArray(rowData.products)) {
                                                                var cartValues = rowData.products;
                                                                for (var i = 0; i < cartValues.length; i++) {
                                                                    var product = cartValues[i];
                                                                    var price = product.Prices[0] * product.ATCQUANTITY;
                                                                    priceAmount+=price;
                                                                }
                                                            } else {
                                                                var cartValues = Object.values(rowData.products);
                                                                for (var i = 0; i < cartValues.length; i++) {
                                                                    var product = cartValues[i];
                                                                    var price = product[0].Prices[0] * product.length;
                                                                    priceAmount+=price;
                                                                }
                                                            }
                                                            
                                                            return `$${priceAmount.toFixed(2)}`;
                                                        } else {
                                                            return rowData[columnDef.field];
                                                        }

                                     
                                                    });
                                                });
                                            
                                                const builder = new CsvBuilder(`Orders-${new Date().toLocaleDateString()}.csv`)
                                                    .setColumns(columnTitles)
                                                    .addRows(csvData)
                                                    .exportFile();
                                            
                                                return builder;
                                            },                                          
                                        }}
                                        columns={[
                                            {title: "Status", field: "status.message"},
                                            {title: "Order Type",render: (rowData) => !rowData.charge || rowData.charge.payAtStore ? "In Store" : "Online"},

                                            {title: "Receipt #", field: "receiptNumber", render: (rowData) => {
                                                return (
                                                    <a onClick={() => {setOpenOrder(rowData)}} href="#">{rowData.receiptNumber}</a>
                                                )
                                            }},
                                            {title: "Customer", field: "firstName", render: (rowData) => rowData.firstName + " " + rowData.lastName},
                                            {title: "Card", field: "card", render: (rowData) => !rowData.charge || !rowData.charge.card || !rowData.charge.card.type || !rowData.charge.card.lastDigits ? "n/a" : `${rowData.charge.card.type} #${rowData.charge.card.lastDigits}`},
                                            { title: "Date", field: "dateCreatedUtc", render: (rowData) => new Date(Date.parse(rowData.dateCreatedUtc)).toLocaleDateString('en-US') },
                                            { title: "Time", field: "dateCreatedUtc", render: (rowData) => new Date(Date.parse(rowData.dateCreatedUtc)).toLocaleTimeString('en-US') },
                                            { title: "Price", render: (rowData) => {
                                            var priceAmount = 0;
                                            if (Array.isArray(rowData.products)) {
                                                var cartValues = rowData.products;
                                                for (var i = 0; i < cartValues.length; i++) {
                                                    var product = cartValues[i];
                                                    var price = product.Prices[0] * product.ATCQUANTITY;
                                                    priceAmount+=price;
                                                }
                                            } else {
                                                var cartValues = Object.values(rowData.products);
                                                for (var i = 0; i < cartValues.length; i++) {
                                                    var product = cartValues[i];
                                                    var price = product[0].Prices[0] * product.length;
                                                    priceAmount+=price;
                                                }
                                            }
                                            
                                            return `$${priceAmount.toFixed(2)}`;
                                            }},
                                            
                                            {title: "", render: (rowData) => {
                                            return (
                                            <>
                                                {!pastOrders && !rowData.status.complete ? (
                                                    <>
                                                    
                                                        <button style={{marginBottom:15}} className='Action-Button' onClick={() => {
                                                            var result = window.confirm("Are you sure you want to confirm this order?");
                                                            if (result) {
                                                                let receiptId = rowData.receiptNumber;
                                                                // Send request to update order
                                                                let token = localStorage.getItem("@token");
                                                                let retailerId = localStorage.getItem("@retailer");
                                                                if (!token || !retailerId) {
                                                                    return window.location.href = "/login";
                                                                }
                                                                fetch( `${hostname}/api/orders/${retailerId}/${receiptId}`, {   'Authorization': `Bearer ${token}`, headers: { 'Content-Type': 'application/json' }, method: "POST", crossDomain:true })
                                                                .then((response) => response.json())
                                                                .then(async (response) => {
                                                                if (response.success) {
                                                                    alert.show('Theres been an order!')
                                                                    setTimeout(() => {
                                                                        window.location.reload();                                                    
                                                                    }, 1500);
                                                                } else {
                                                                    if (response.error == "not logged in") {
                                                                        localStorage.removeItem("@token");
                                                                        window.location.href = "/login";
                                                                    }
                                                                    alert.show(response.message);
                                                                }
                        
                                                                })
                                                                .catch((err) => {console.log(err);
                                                                }); 
                                                            }
                                            
                                                            }}>Confirm Order
                                                        </button>
                                                    </>
                                                ): null }
                    
                                                {
                                                    // rowData.status.message !== "Cancelled" ? (
                                                    //     <button style={{backgroundColor:'rgba(184, 184, 184, 1)'}}className='Action-Button' onClick={() => {
                                                    //         var result = window.confirm("Are you sure you want to cancel this order?");
                                                    //         if (result) {
                                                    //             let receiptId = rowData.receiptNumber;
                                                    //             // Send request to update order
                                                    //             let token = localStorage.getItem("@token");
                                                    //             if (!token) {
                                                    //                 return window.location.href = "/login";
                                                    //             }
                                                    //             fetch( `${hostname}/api/orders/5f46acf986efa201151435a3/${receiptId}`, {   'Authorization': `Bearer ${token}`, headers: { 'Content-Type': 'application/json' }, method: "DELETE", crossDomain:true })
                                                    //             .then((response) => response.json())
                                                    //             .then(async (response) => {
                                                    //             if (response.success) {
                                                    //                 alert.show(`Order, ${receiptId}, has been cancelled.`);
                                                    //                 setTimeout(() => {
                                                    //                     window.location.reload();
                                                    //                 }, 1500);
                                                    //             } else {
                                                    //                 if (response.error == "not logged in") {
                                                    //                     localStorage.removeItem("@token");
                                                    //                     window.location.href = "/login";
                                                    //                 }
                                                    //                 alert.show(response.message);
                                                    //             }
                                
                                                    //             })
                                                    //             .catch((err) => {console.log(err);
                                                    //             });
                                                    //         }
                            
                                                    //     }}>Refund Order</button>
                                                    // ) : null
                                                }
                                            </>                    
                                        )
                                        }}
                                    ]}
                                    data={ordersFiltered}
                                    title={`All Orders`}
                                    />
                                                
                                ) : (
                                    <CircleLoader></CircleLoader>
                                )
                            }
            
                        </div>
            
            
                    </div>
            ) : null
        }
   


        {
            openOrder !== null ? (
                <div className="App-Body">
                   <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60, display:'flex', alignItems:"center"}}>
                        <h1 style={{cursor:"pointer", color:"#5096FF"}}  onClick={() => {setOpenOrder(null)}}>
                        <FontAwesomeIcon style={{marginRight:5}} icon={faCaretLeft} />
                            Back to all orders</h1>
                        {/* <a href="#" onClick={() => {setOpenOrder(null)}} style={{margin:"auto"}}> {"< "} Back to all orders</a> */}
                    </div>



                    <div className="Info-div">
                            <h2>Order #{openOrder.receiptNumber}</h2>

                            <div className="Form-Input">
                                <label>Name</label>
                                <input className="input-field" value={openOrder.firstName + " " + openOrder.lastName} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Phone</label>
                                <input className="input-field" value={openOrder.phone} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Payment Card</label>
                                <input className="input-field" value={!openOrder.card ? "n/a" : openOrder.card} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Order Placed</label>
                                <input className="input-field" value={`${ new Date(Date.parse(openOrder.dateCreatedUtc)).toLocaleDateString('en-US')} @ ${new Date(Date.parse(openOrder.dateCreatedUtc)).toLocaleTimeString('en-US')}`} disabled></input>
                            </div>

                    </div>



                    <div className="Info-div">
                            <h2>Order Items ({Array.isArray(openOrder.products) ? openOrder.products.length: Object.keys(openOrder.products).length})</h2>
                            {

                                Array.isArray(openOrder.products) ? (
                                    <>
                                        {
                                            openOrder.products.map((product, i) => (

                                                <div style={{paddingTop:8, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: "#FFF", border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                                    <img style={{width:40, height:40, marginRight:15}} src={product.Image}></img>
                                                    <div style={{maxWidth:225}}>
                                                        <h5 style={{color: "#A6A6A6",fontSize:12, fontWeight:600, marginTop:0, marginBottom:0}}>{product.brandName}</h5>
                                                        <p style={{color: "#000", fontSize: 12, margin:0}}>{product.Name}</p>
                                                    </div>
                                                    <div style={{marginLeft: 'auto'}}>
                                                        <span style={{fontSize:12}} className="Checkout-Summary-Quantity">{product.ATCQUANTITY} x ${product.Prices[0].toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </>
                                ) : (
                                    Object.values(openOrder.products).map((val) => (
                                        <div style={{paddingTop:8, paddingBottom:8, paddingLeft:15, paddingRight:15, borderRadius:5, background: "#FFF", border: "1px solid #D6D6D6", marginTop:15, alignItems:'center',  display:'flex', flexDirection:'row'}}>
                                            <img style={{width:40, height:40, marginRight:15}} src={val[0].Image}></img>
                                            <div style={{maxWidth:225}}>
                                                <h5 style={{color: "#A6A6A6",fontSize:12, fontWeight:600, marginTop:0, marginBottom:0}}>{val[0].brandName}</h5>
                                                <p style={{color: "#000", fontSize: 12, margin:0}}>{val[0].Name}</p>
                                            </div>
                                            <div style={{marginLeft: 'auto'}}>
                                                <span style={{fontSize:12}} className="Checkout-Summary-Quantity">{val[0].ATCQUANTITY} x ${val[0].Prices[0].toFixed(2)}</span>
                                            </div>
                                        </div>
                                    ))
                                )
                                //  if (Array.isArray(rowData.products)) {
                                //                 var cartValues = rowData.products;
                                //                 for (var i = 0; i < cartValues.length; i++) {
                                //                     var product = cartValues[i];
                                //                     var price = product.Prices[0] * product.ATCQUANTITY;
                                //                     priceAmount+=price;
                                //                 }
                                //             } else {
                                //                 var cartValues = Object.values(rowData.products);
                                //                 for (var i = 0; i < cartValues.length; i++) {
                                //                     var product = cartValues[i];
                                //                     var price = product[0].Prices[0] * product.length;
                                //                     priceAmount+=price;
                                //                 }
                                //             }
                            }


                                {openOrder.status.message !== "Cancelled" ? (
                                     <div style={{justifyContent:"flex-start"}} className="Form-Input">
                                     <button 
                                     
                                     onClick={() => {
                                         var result = window.confirm("Are you sure you want to cancel this order?");
                                         if (result) {
                                             let receiptId = openOrder.receiptNumber;
                                             // Send request to update order
                                             let token = localStorage.getItem("@token");
                                            let retailerId = localStorage.getItem("@retailer");
                                            if (!token || !retailerId) {
                                                return window.location.href = "/login";
                                            }
                                             fetch( `${hostname}/api/orders/${retailerId}/${receiptId}`, {   'Authorization': `Bearer ${token}`, headers: { 'Content-Type': 'application/json' }, method: "DELETE", crossDomain:true })
                                             .then((response) => response.json())
                                             .then(async (response) => {
                                             if (response.success) {
                                                 alert.show(`Order, ${receiptId}, has been cancelled.`);
                                                 setTimeout(() => {
                                                     window.location.reload();
                                                 }, 1500);
                                             } else {
                                                 if (response.error == "not logged in") {
                                                     localStorage.removeItem("@token");
                                                     window.location.href = "/login";
                                                 }
                                                 alert.show(response.message);
                                             }
             
                                             })
                                             .catch((err) => {console.log(err);
                                             });
                                         }
         
                                     }}
                                     className="" style={{borderRadius:100, height:40, color:"#fff", border:"none", backgroundColor:"#5096FF", paddingLeft:35, paddingRight:35}}>Cancel Order</button>
                                 </div>
                                ) : null}
                            


                           

                        </div>

                </div>

            ) : null
        }
    
    
        </div>
      );
}

export default Orders;