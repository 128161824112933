import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, ListGroup, Container, Button, Modal} from 'react-bootstrap';
import heedIcon from "../heed.png";
import "../Settings.css";
import Multiselect from 'multiselect-react-dropdown';
import Catalogue from "../Components/Catalaogue/Catalogue";
import Category from "../Components/Category/Category";
import { useAlert } from 'react-alert'
import blueIcon from "../blue_logo.png";
import { auth, sendPasswordResetEmail, createUserWithEmailAndPassword, signOut } from "../firebase";
import retailerIcon from "../retailer.jpeg";
import CircleLoader from "../Components/CircleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDoorOpen, faFloppyDisk, faClipboard, faPlus, faChartSimple, faBoxesStacked, faUser, faBars, faGear } from '@fortawesome/free-solid-svg-icons'
import { Draggable } from "react-drag-reorder";
import Select from 'react-draggable-multi-select';
import DatePicker from "react-datepicker";
import { ChromePicker } from 'react-color'

import "react-datepicker/dist/react-datepicker.css";

function Settings({}) {
    const alert = useAlert()

    const [show, setShow] = useState(false);
    const [showSH, setShowSH] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleCloseSH = () => setShowSH(false);
    const handleShowSH = () => setShowSH(true);
    // const hostname = "http://localhost:5000";

    
    const hostname = "https://heed.herokuapp.com";
    const [page, setPage] = useState("Store info");
    const [accountRole, setAccountRole] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [payAtStore, setPayAtStore] = useState(false);
    const [pickUp, setPickUp] = useState(true);
    const [delivery, setDelivery] = useState(false);

    const [account, setAccount] = useState(null);

    const [accountName, setAccountName] = useState("");
    const [accountEmail, setAccountEmail] = useState("");
    const [accountLocation, setAccountLocation] = useState(["61239787a61dca00a274ac5f"]);
    const [newAccountRole, setNewAccountRole] = useState("admin");

    const [SHName, setSHName] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState("");
    const [specialDays, setSpecialDays] = useState([]);
    const [specialDaysValues, setSpecialDaysValues] = useState([]);
    const [specialHours, setSpecialHours] = useState([]);

    const [title, setTitle] = useState("");
    const [banner, setBanner] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    
    const [categoryAdded, setCategoryAdded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [catalogueAdded, setCatalogueAdded] = useState(false);
    const [catalogue, setCatalogue] = useState([]); 
    const [upsells, setUpsells] = useState([]);
    const [quantityThreshold, setQuantityThreshold] = useState(2);
    const [products, setProducts] = useState([]);
    const [productsMultiSelect, setProductsMultiSelect] = useState([]);

    // Hours
    const [openSunday, setOpenSunday] = useState(true);
    const [sundayOpen, setSundayOpen] = useState("0:0");
    const [sundayClose, setSundayClose] = useState("11:0");

    const [openMonday, setOpenMonday] = useState(true);
    const [mondayOpen, setMondayOpen] = useState("0:0");
    const [mondayClose, setMondayClose] = useState("11:0");

    const [openTuesday, setOpenTuesday] = useState(true);
    const [tuesdayOpen, setTuesdayOpen] = useState("0:0");
    const [tuesdayClose, setTuesdayClose] = useState("11:0");

    const [openWednesday, setOpenWednesday] = useState(true);
    const [wednesdayOpen, setWednesdayOpen] = useState("0:0");
    const [wednesdayClose, setWednesdayClose] = useState("11:0");

    const [openThursday, setOpenThursday] = useState(true);
    const [thursdayOpen, setThursdayOpen] = useState("0:0");
    const [thursdayClose, setThursdayClose] = useState("11:0");

    const [openFriday, setOpenFriday] = useState(true);
    const [fridayOpen, setFridayOpen] = useState("0:0");
    const [fridayClose, setFridayClose] = useState("11:0");

    const [openSaturday, setOpenSaturday] = useState(true);
    const [saturdayOpen, setSaturdayOpen] = useState("0:0");
    const [saturdayClose, setSaturdayClose] = useState("11:0");
    
    const [loading, setLoading] = useState(true);

    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [textColor, setTextColor] = useState("#323232");
    const [secondaryTextColor, setSecondaryTextColor] = useState("#A5A5A5");
    const [accentColor, setAccentColor] = useState("#5096ff");
    useEffect(() => {
        fetchRetailer();
        fetchProducts();
        setTimeout(() => {
            window.location.reload();
        }, 1800000);
    }, []);

    useEffect(() => {
        if (selectedFile) {
            // Upload image
            const formData = new FormData();
            formData.append('upload', selectedFile);
            // post form data
            const xhr = new XMLHttpRequest();
            // log response
            xhr.onload = () => {
                var response = JSON.parse(xhr.responseText);
                console.log(response);
                if (response.success) {
                    setBanner(response.image);
                } else {
                    alert.show(response.error);
                }
            };
            // create and send the reqeust
            xhr.open('POST', `${hostname}/api/retailer/image`);
            xhr.send(formData);
        }
    }, [selectedFile]);
   
    useEffect(() => {
        var options = products.map(product => {return{value: product.id, label: `${product.Name} (${product.type})`}});
        setProductsMultiSelect(options);
    }, [products]);

    useEffect(() => {
        if (categoryAdded === true) {
            setCategoryAdded(false);
        }
        return;
    }, [categoryAdded]);

    useEffect(() => {
        if (catalogueAdded === true) {
            setCatalogueAdded(false);
        }
        return;
    }, [catalogueAdded])
   

    const fetchRetailer = () => {
        setLoading(true);
        let token = localStorage.getItem("@token");
        let user = localStorage.getItem("@user");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !user || !retailerId) {
            return window.location.href = "/login";
        }
        console.log(user);
        setAccount(user);
        fetch( `${hostname}/api/retailer/${retailerId}`, {  headers: {  'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                let retailer = response.data[0];
                // Set store info
                setAccountRole(retailer.account ? retailer.account.role : "");
                setAccounts(retailer.accounts);
                setAddress(retailer.location.address);
                setPhone(retailer.phone);
                setEmail(retailer.email);
                setWebsite(retailer.website);
                setPickUp(retailer.settings.pickUp);
                setDelivery(retailer.settings.delivery);
                setPayAtStore(retailer.settings.payAtStore);
                setQuantityThreshold(retailer.settings.quantityThreshold);

                // Set hours
                setOpenSunday(retailer.hours.Sunday.display);
                setSundayOpen(`${retailer.hours.Sunday.open[0]}:${retailer.hours.Sunday.open[1]}`);
                setSundayClose(`${retailer.hours.Sunday.close[0]}:${retailer.hours.Sunday.close[1]}`);

                setOpenMonday(retailer.hours.Monday.display);
                setMondayOpen(`${retailer.hours.Monday.open[0]}:${retailer.hours.Monday.open[1]}`);
                setMondayClose(`${retailer.hours.Monday.close[0]}:${retailer.hours.Monday.close[1]}`);

                setOpenTuesday(retailer.hours.Tuesday.display);
                setTuesdayOpen(`${retailer.hours.Tuesday.open[0]}:${retailer.hours.Tuesday.open[1]}`);
                setTuesdayClose(`${retailer.hours.Tuesday.close[0]}:${retailer.hours.Tuesday.close[1]}`);

                setOpenWednesday(retailer.hours.Wednesday.display);
                setWednesdayOpen(`${retailer.hours.Wednesday.open[0]}:${retailer.hours.Wednesday.open[1]}`);
                setWednesdayClose(`${retailer.hours.Wednesday.close[0]}:${retailer.hours.Wednesday.close[1]}`);

                setOpenThursday(retailer.hours.Thursday.display);
                setThursdayOpen(`${retailer.hours.Thursday.open[0]}:${retailer.hours.Thursday.open[1]}`);
                setThursdayClose(`${retailer.hours.Thursday.close[0]}:${retailer.hours.Thursday.close[1]}`);

                setOpenFriday(retailer.hours.Friday.display);
                setFridayOpen(`${retailer.hours.Friday.open[0]}:${retailer.hours.Friday.open[1]}`);
                setFridayClose(`${retailer.hours.Friday.close[0]}:${retailer.hours.Friday.close[1]}`);

                setOpenSaturday(retailer.hours.Friday.display);
                setSaturdayOpen(`${retailer.hours.Friday.open[0]}:${retailer.hours.Saturday.open[1]}`);
                setSaturdayClose(`${retailer.hours.Saturday.close[0]}:${retailer.hours.Saturday.close[1]}`);

                setSpecialHours(retailer.specialHours);

                // Set customizations
                setTitle(retailer.catalogue.title);
                setBanner(retailer.catalogue.banner)
                setCatalogue(retailer.catalogue.catalogue);
                setCategories(retailer.catalogue.categories);
                var upsellsSelected = retailer.catalogue.upsells.map(upsell => (
                    {
                      label: upsell.name,
                      value: upsell.id
                    } 
                ));
                  
                setUpsells(upsellsSelected);

                // Branding
                if (retailer.settings.branding) {
                    if (retailer.settings.branding.backgroundColor) {
                        setBackgroundColor(retailer.settings.branding.backgroundColor);
                    }
                    if (retailer.settings.branding.textColor) {
                        setTextColor(retailer.settings.branding.textColor);
                    }
                    if (retailer.settings.branding.secondaryTextColor) {
                        setSecondaryTextColor(retailer.settings.branding.secondaryTextColor);
                    }
                    if (retailer.settings.branding.accentColor) {
                        setAccentColor(retailer.settings.branding.accentColor);
                    }
                }

                setLoading(false);


            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err);
        });  
    }


    const fetchProducts = () => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/menu/${retailerId}/products`, { headers: {  'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({}), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                setProducts(response.data);
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err);
        });
    }
    

    const saveRetailer = () => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/retailer/${retailerId}/info`, { headers: {  'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "PUT", body: JSON.stringify({
            address: address,
            email: email,
            phone: phone,
            website: website,
            payAtStore: payAtStore,
            delivery: delivery,
            pickUp: pickUp
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                alert.show(`Updated retailer information!`);
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                } 
                alert.show(response.message);
            }
        })
        .catch((err) => {console.log(err);
        }); 
    }

    const saveHours = () => {
        var hours = {
            Sunday: {
                display: openSunday,
                open: [parseInt(sundayOpen.split(":")[0]), parseInt(sundayOpen.split(":")[1])],
                close: [parseInt(sundayClose.split(":")[0]), parseInt(sundayClose.split(":")[1])]
            },
            Monday: {
                display: openMonday,
                open: [parseInt(mondayOpen.split(":")[0]), parseInt(mondayOpen.split(":")[1])],
                close: [parseInt(mondayClose.split(":")[0]), parseInt(mondayClose.split(":")[1])]
            },
            Tuesday: {
                display: openTuesday,
                open: [parseInt(tuesdayOpen.split(":")[0]), parseInt(tuesdayOpen.split(":")[1])],
                close: [parseInt(tuesdayClose.split(":")[0]), parseInt(tuesdayClose.split(":")[1])]
            },
            Wednesday: {
                display: openWednesday,
                open: [parseInt(wednesdayOpen.split(":")[0]), parseInt(wednesdayOpen.split(":")[1])],
                close: [parseInt(wednesdayClose.split(":")[0]), parseInt(wednesdayClose.split(":")[1])]
            },
            Thursday: {
                display: openThursday,
                open: [parseInt(thursdayOpen.split(":")[0]), parseInt(thursdayOpen.split(":")[1])],
                close: [parseInt(thursdayClose.split(":")[0]), parseInt(thursdayClose.split(":")[1])]
            },
            Friday: {
                display: openFriday,
                open: [parseInt(fridayOpen.split(":")[0]), parseInt(fridayOpen.split(":")[1])],
                close: [parseInt(fridayClose.split(":")[0]), parseInt(fridayClose.split(":")[1])]
            },
            Saturday: {
                display: openSaturday,
                open: [parseInt(saturdayOpen.split(":")[0]), parseInt(saturdayOpen.split(":")[1])],
                close: [parseInt(saturdayClose.split(":")[0]), parseInt(saturdayClose.split(":")[1])]
            },
        };
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/retailer/${retailerId}/hours`, {  headers: {  'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "PUT", body: JSON.stringify({
            hours: hours
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                alert.show(`Updated store hours!`);
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
                alert.show(response.message);
            }
        })
        .catch((err) => {console.log(err);
        }); 
    }

    const removeBanner = () => {
        setBanner("");
    }

    const saveCustomizations = () => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        let postUpsells = upsells.map(upsell => (
            {
              name: upsell.label,
              id: upsell.value
            } 
        ));
        // var upsellIds = upsells.map(upsell => upsell.id);
        fetch( `${hostname}/api/retailer/${retailerId}/menu`, {  headers: {  'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "PUT", body: JSON.stringify({
            title: title,
            banner: banner,
            quantityThreshold: parseInt(quantityThreshold),
            upsells: postUpsells
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                alert.show(`Updated menu storefront!`);
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
                alert.show(response.message);
            }
        })
        .catch((err) => {console.log(err);
        }); 
    }

    var swapArrayElements = (arr, indexA, indexB) => {
        if (indexB >= arr.length) {
            var k = indexB - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(indexB, 0, arr.splice(indexA, 1)[0]);
        return arr; // for testing
    };

    const reArrangeCatalogues = (currentPos, newPos) => {
        var newCatalogueOrder = catalogue;
        swapArrayElements(newCatalogueOrder, currentPos, newPos);
        setCatalogue(newCatalogueOrder);
    }

    const saveCataloguePositions = () => {

        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        // var upsellIds = upsells.map(upsell => upsell.id);
        fetch( `${hostname}/api/retailer/${retailerId}/menu/catalogues`, {  headers: {  'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "PUT", body: JSON.stringify({
            shelves: catalogue
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                alert.show(`Updated shelves!`);
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
                alert.show(response.message);
            }
        })
        .catch((err) => {console.log(err);
        }); 
        
    }



    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    useEffect(() => {
        console.log(startDate);

        var dateArray = [];
        var values = [];
        var currentDate = startDate;
        while (currentDate <= endDate) {
            dateArray.push(new Date (currentDate));
            currentDate = currentDate.addDays(1);
            values.push({
                date: new Date (currentDate),
                display: false,
                open: [8, 0],
                close: [20, 30]
            })
        }

        setSpecialDays(dateArray);
        setSpecialDaysValues(values);
        
    }, [startDate]);

    

    useEffect(() => {
        console.log(startDate, endDate);

    
        var dateArray = [];
        var currentDate = startDate;
        var values = [];
        while (currentDate <= endDate) {
            dateArray.push(new Date (currentDate));
            currentDate = currentDate.addDays(1);
            values.push({
                date: new Date (currentDate),
                display: true,
                open: [8, 0],
                close: [20, 30]
            })
        }

        setSpecialDays(dateArray);
        setSpecialDaysValues(values);

    }, [endDate]);


    const saveSpecialHours = () => {
        // Submit form to create user
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        if (!SHName ) {
            handleCloseSH();
            alert.show("Please fill in all fields!");
            return;
        }

        fetch( `${hostname}/api/retailer/${retailerId}/hours/specials`, { headers: {  'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({
            hours: specialDaysValues,
            name: SHName
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                handleCloseSH();
                alert.show("Added special hours!");
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
                handleCloseSH();
                alert.show(response.error);
            }
        })
        .catch((err) => {console.log(err);
        });

    }


    const saveBranding = () => {
        // Submit form to create user
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }


        fetch( `${hostname}/api/retailer/${retailerId}/branding`, { headers: {  'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({
            backgroundColor: backgroundColor,
            textColor: textColor,
            secondaryTextColor: secondaryTextColor,
            accentColor: accentColor
        }), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                handleCloseSH();
                alert.show("Updated brand colors!");
                fetchRetailer();
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
                alert.show(response.error);
            }
        })
        .catch((err) => {console.log(err);
        });
    }

    return (
        <div className="App">

            
        <Navbar className="Mobile-Nav navbar-light" expand="lg">
        <Container>
            <Navbar.Brand href="#">
                <img src={blueIcon} width="50" height="50" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link href="/">Analytics</Nav.Link>
                    <Nav.Link href="/orders">Orders</Nav.Link>
                    <Nav.Link href="/customers">Customers</Nav.Link>
                    <Nav.Link href="/menu">Menu</Nav.Link>
                    <Nav.Link href="/settings">Settings</Nav.Link>
                    <Nav.Link href="#">Support</Nav.Link>
                    <Nav.Link onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} href="#">Logout</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <div className='Sidebar'>

        {
            localStorage.getItem("@image") ? 
            (
              <img className="Retailer" src={localStorage.getItem("@image")}></img>
            ) : null
          }
        {localStorage.getItem("@location") ? ( 
          <h5 style={{fontSize:12, fontWeight:"bold", marginTop:15}}>{localStorage.getItem("@location")}</h5>
        ) : null}
        
        <div className="Sidebar-Middle">
             <ListGroup as="ul">
                 <ListGroup.Item onClick={() => {window.location.href = '/'}} as="li" style={{cursor: 'pointer'}} >
                   <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faChartSimple} />
                    Analytics
                </ListGroup.Item>

                <ListGroup.Item onClick={() => {window.location.href = '/orders'}} as="li" style={{cursor: 'pointer'}} >
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBoxesStacked} />
                    Orders
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/customers'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faUser} />
                    Customers
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/menu'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBars} />
                    Menu
                </ListGroup.Item>
                <ListGroup.Item active onClick={() => {window.location.href = '/settings'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faGear} />
                    Settings
                </ListGroup.Item>
            </ListGroup>
        </div>

        <div className="Sidebar-Bottom">
            <div onClick={() => {window.location.href = "/location"}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
              <FontAwesomeIcon style={{cursor:"pointer", fontSize:36, color:"#8bb8fc"}} icon={faDoorOpen} />
              <p style={{color:"#8bb8fc", marginTop:5}}>Switch Location</p>
            </div>
            {/* <img className='Logo' src={heedIcon}></img> */}

            <div>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Support
                </ListGroup.Item>
        
                <ListGroup.Item onClick={() => {
                    localStorage.removeItem("@token");
                    localStorage.removeItem("@user");
                    window.location.href = "/login";
                }} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Logout
               </ListGroup.Item>
            </div>

        </div>



        </div>

            <div className="App-Body">

                <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                    <h1>Settings</h1>
                </div>

                <div className="Body-Header">
                    <a href="#" onClick={() => {setPage('Store info');}}  className={page === "Store info" ? "active-header" : ""} style={{marginRight:15}}>
                        Store info
                    </a>

                    <a href="#" onClick={() => {setPage('Hours');}}  className={page === "Hours" ? "active-header" : ""} style={{marginRight:15}}>
                        Hours
                    </a>

                    <a href="#" onClick={() => {setPage('Customize');}} className={page === "Customize" ? "active-header" : ""} style={{marginRight:15}}>
                        Customize
                    </a>


                    <a href="#" onClick={() => {setPage('Deploy');}} className={page === "Deploy" ? "active-header" : ""} style={{marginRight:15}}>
                        Deploy
                    </a>


                    <a href="#" onClick={() => {setPage('Account');}} className={page === "Account" ? "active-header" : ""} style={{marginRight:15}}>
                        Account
                    </a>

                </div>



                {
                    loading === false ? (
                        <>
                            {
                                page === "Store info" ? (
                                    <div className="Info-div">
                                        <h2>Retailer</h2>

                                        <div className="Form-Input">
                                            <label>Address</label>
                                            <input className="input-field" value={address} onChange={((e) => {setAddress(e.target.value)})}></input>
                                        </div>

                                        <div className="Form-Input">
                                            <label>Phone</label>
                                            <input className="input-field" value={phone} onChange={((e) => {setPhone(e.target.value)})}></input>
                                        </div>


                                        <div className="Form-Input">
                                            <label>Email</label>
                                            <input className="input-field" value={email} onChange={((e) => {setEmail(e.target.value)})}></input>
                                        </div>


                                        <div className="Form-Input">
                                            <label>Website</label>
                                            <input className="input-field" value={website} onChange={((e) => {setWebsite(e.target.value)})}></input>
                                        </div>


                                        {/* <div style={{marginBottom:30}} className="helper">
                                            <p>If toggled this gives your customers the ability to make the payment in-store. This means they won't be prompted to checkout online.</p>
                                        </div>
                                        <div className="Form-Input">
                                            <label>Pay at Store</label>
                                            <input checked={payAtStore} onChange={(e) => {setPayAtStore(e.target.checked)}} type="checkbox" />
                                        </div> */}
                                        {/* <p className="small-helper">If toggled this gives your customers the ability to make the payment in-store. This means they won't be prompted to checkout online.</p> */}
                                    
                                        {/* <div className="Form-Input">
                                            <label>Pick up</label>
                                            <input checked={pickUp} onChange={(e) => {setPickUp(e.target.checked)}} value={pickUp} type="checkbox" />
                                        </div>

                                        

                                        <div className="Form-Input">
                                            <label>Delivery</label>
                                            <input checked={delivery} onChange={(e) => {setDelivery(e.target.checked)}} value={delivery} type="checkbox" />
                                        </div> */}



                                        <div style={{justifyContent:"flex-start"}} className="Form-Input">
                                            <button onClick={() => {
                                                saveRetailer();
                                            }} className="Save">
                                                <FontAwesomeIcon style={{}} icon={faFloppyDisk} />
                                            </button>
                                        </div>

                                    </div>
                                    
                                ) : null
                            }

                            {
                                page === "Hours" ? (
                                    <>

                                    <div className="Info-div">

                                        <h2>Store Hours</h2>
                                        <div className="Form-Input">
                                            <input style={{marginRight: 15}} checked={openSunday} onChange={(e) => {setOpenSunday(e.target.checked)}} type="checkbox" />
                                            <label>Sunday</label>
                                                    <select value={sundayOpen} onChange={(e) => {setSundayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={sundayClose} onChange={(e) => {setSundayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                        </div>

                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openMonday} onChange={(e) => {setOpenMonday(e.target.checked)}} type="checkbox" />
                                            <label>Monday</label>
                                                    <select value={mondayOpen} onChange={(e) => {setMondayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={mondayClose} onChange={(e) => {setMondayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                        </div>

                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openTuesday} onChange={(e) => {setOpenTuesday(e.target.checked)}} type="checkbox" />
                                            <label>Tuesday</label>
                                                    <select value={tuesdayOpen} onChange={(e) => {setTuesdayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={tuesdayClose} onChange={(e) => {setTuesdayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                        </div>

                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openWednesday} onChange={(e) => {setOpenWednesday(e.target.checked)}} type="checkbox" />
                                            <label>Wednesday</label>
                                                    <select value={wednesdayOpen} onChange={(e) => {setWednesdayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={wednesdayClose} onChange={(e) => {setWednesdayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                        </div>


                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openThursday} onChange={(e) => {setOpenThursday(e.target.checked)}} type="checkbox" />
                                            <label>Thursday</label>
                                                    <select value={thursdayOpen} onChange={(e) => {setThursdayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={thursdayClose} onChange={(e) => {setThursdayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                        </div>

                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openFriday} onChange={(e) => {setOpenFriday(e.target.checked)}} type="checkbox" />
                                            <label>Friday</label>
                                                    <select value={fridayOpen} onChange={(e) => {setFridayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={fridayClose} onChange={(e) => {setFridayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                        </div>
                                    

                                        <div className="Form-Input">
                                        <input style={{marginRight: 15}} checked={openSaturday} onChange={(e) => {setOpenSaturday(e.target.checked)}} type="checkbox" />
                                            <label>Saturday</label>
                                                    <select value={saturdayOpen} onChange={(e) => {setSaturdayOpen(e.target.value)}} className="time-picker">
                                                        <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>

                                                    <select value={saturdayClose} onChange={(e) => {setSaturdayClose(e.target.value)}} className="time-picker">
                                                    <option value="0:0">12:00 AM</option>
                                                        <option value="0:30">12:30 AM</option>
                                                        <option value="1:0">01:00 AM</option>
                                                        <option value="1:30">01:30 AM</option>
                                                        <option value="2:0">02:00 AM</option>
                                                        <option value="2:30">02:30 AM</option>
                                                        <option value="3:0">03:00 AM</option>
                                                        <option value="3:30">03:30 AM</option>
                                                        <option value="4:0">04:00 AM</option>
                                                        <option value="4:30">04:30 AM</option>
                                                        <option value="5:0">05:00 AM</option>
                                                        <option value="5:30">05:30 AM</option>
                                                        <option value="6:0">06:00 AM</option>
                                                        <option value="6:30">06:30 AM</option>
                                                        <option value="7:0">07:00 AM</option>
                                                        <option value="7:30">07:30 AM</option>
                                                        <option value="8:0">08:00 AM</option>
                                                        <option value="8:30">08:30 AM</option>
                                                        <option value="9:0">09:00 AM</option>
                                                        <option value="9:30">09:30 AM</option>
                                                        <option value="10:0">10:00 AM</option>
                                                        <option value="10:30">10:30 AM</option>
                                                        <option value="11:0">11:00 AM</option>
                                                        <option value="11:30">11:30 AM</option>
                                                        <option value="12:0">12:00 PM</option>
                                                        <option value="12:30">12:30 PM</option>
                                                        <option value="13:0">01:00 PM</option>
                                                        <option value="13:30">01:30 PM</option>
                                                        <option value="14:0">02:00 PM</option>
                                                        <option value="14:30">02:30 PM</option>
                                                        <option value="15:0">03:00 PM</option>
                                                        <option value="15:30">03:30 PM</option>
                                                        <option value="16:0">04:00 PM</option>
                                                        <option value="16:30">04:30 PM</option>
                                                        <option value="17:0">05:00 PM</option>
                                                        <option value="17:30">05:30 PM</option>
                                                        <option value="18:0">06:00 PM</option>
                                                        <option value="18:30">06:30 PM</option>
                                                        <option value="19:0">07:00 PM</option>
                                                        <option value="19:30">07:30 PM</option>
                                                        <option value="20:0">08:00 PM</option>
                                                        <option value="20:30">08:30 PM</option>
                                                        <option value="21:0">09:00 PM</option>
                                                        <option value="21:30">09:30 PM</option>
                                                        <option value="22:0">10:00 PM</option>
                                                        <option value="22:30">10:30 PM</option>
                                                        <option value="23:0">11:00 PM</option>
                                                        <option value="23:30">11:30 PM</option>
                                                    </select>
                                            </div>
                                    
                                            <div style={{justifyContent:"flex-start"}}  className="Form-Input">
                                                <button onClick={() => {
                                                    saveHours();
                                                }} className="Save">
                                                    <FontAwesomeIcon style={{}} icon={faFloppyDisk} />
                                                </button>

                                            </div>

                                    </div>

                                    <div className="Info-div">

                                        <h2>Special Hours</h2>
                                        
                                        {specialHours.map((hoursCollection => {
                                            return (
                                                <div className="Shelve">
                                                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                        <h5 style={{margin:0, marginTop:0, marginBottom:0, marginRight:15, fontSize:16}}>{hoursCollection.name}</h5>
                                                        <span>|</span>
                                                        <p style={{margin:0, marginLeft:15, marginRight:10, fontSize:16}}>{new Date(hoursCollection.hours[0].date).toDateString()} - {new Date(hoursCollection.hours[hoursCollection.hours.length-1].date).toDateString()}</p>
                                                    </div>
                                        
                                                    <div onClick={() => {
                                                        var result = window.confirm(`Are you sure you want to delete ${hoursCollection.name} hours?`);
                                                        if (result) {
                                                
                                                            let token = localStorage.getItem("@token");
                                                            let retailerId = localStorage.getItem("@retailer");
                                                            if (!token || !retailerId) {
                                                                return window.location.href = "/login";
                                                            }
                                                
                                                            // Delete index
                                                            const form = {
                                                                name: hoursCollection.name
                                                            }
                                                            fetch( `${hostname}/api/retailer/${retailerId}/hours/specials`, {    headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json' }, method: "DELETE", body: JSON.stringify(form), crossDomain:true })
                                                            .then((response) => response.json())
                                                            .then(async (response) => {
                                                                if (response.success) {
                                                                    handleClose();
                                                                    fetchRetailer();
                                                                    alert.show(`Deleted ${hoursCollection.name} hours.`);
                                                                }
                                                            })
                                                            .catch((err) => {console.log(err);
                                                            });
                                                        }
                                                    }} style={{cursor:"pointer"}}>
                                                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                    </div>
                                                </div>
                                            )                                         
                                        }))}

                                        <button style={{marginTop:15, borderRadius:100, height:40, color:"#fff", border:"none", backgroundColor:"#5096FF", paddingLeft:35, paddingRight:35}} onClick={() => {handleShowSH()}} className="">
                                            Add Special Hours 
                                        </button>


                                        <Modal className="Add-special-hours-modal" dialogClassName="modal-90w" fullscreen={"sm-down"} show={showSH} onHide={handleCloseSH} animation={true}>
                                            <Modal.Header closeButton>
                                            <Modal.Title>Add Special Hours</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div style={{marginTop:0}}>
                                                    <label style={{marginBottom:15}}>Name</label>
                                                    <input style={{width:"100%"}} placeholder="Christmas" onChange={(e) => {setSHName(e.target.value)}} value={SHName}></input>
                                                </div>
                                                <div className="form-group" style={{marginTop:30}}>
                                                    <label style={{marginBottom:15}}>Start Date</label>
                                                    <DatePicker style={{position:"relative", width:"100%"}} selected={startDate} onChange={(date) => setStartDate(date)} />
                                                </div>

                                                <div className="form-group" style={{marginTop:30}}>
                                                    <label style={{marginBottom:15}}>End Date</label>
                                                    <DatePicker style={{position:"relative", width:"100%"}} selected={endDate} onChange={(date) => setEndDate(date)} />
                                                </div>

                                                {
                                                    specialDaysValues.map((day, elem) => {
                                                        return (
                                                            <div className="Form-Input">
                                                                <input checked onChange={(e) => {var newArr = specialDaysValues; newArr[elem].display = e.target.checked; setSpecialDaysValues(newArr); console.log(newArr);}} style={{marginRight: 15}} type="checkbox" />
                                                                <label>{day.date.toDateString()}</label>
                                                                <select onChange={(e) => {var newArr = specialDaysValues; newArr[elem].open = e.target.value}}  className="time-picker">
                                                                    <option value="0:0">12:00 AM</option>
                                                                    <option value="0:30">12:30 AM</option>
                                                                    <option value="1:0">01:00 AM</option>
                                                                    <option value="1:30">01:30 AM</option>
                                                                    <option value="2:0">02:00 AM</option>
                                                                    <option value="2:30">02:30 AM</option>
                                                                    <option value="3:0">03:00 AM</option>
                                                                    <option value="3:30">03:30 AM</option>
                                                                    <option value="4:0">04:00 AM</option>
                                                                    <option value="4:30">04:30 AM</option>
                                                                    <option value="5:0">05:00 AM</option>
                                                                    <option value="5:30">05:30 AM</option>
                                                                    <option value="6:0">06:00 AM</option>
                                                                    <option value="6:30">06:30 AM</option>
                                                                    <option value="7:0">07:00 AM</option>
                                                                    <option value="7:30">07:30 AM</option>
                                                                    <option value="8:0">08:00 AM</option>
                                                                    <option value="8:30">08:30 AM</option>
                                                                    <option value="9:0">09:00 AM</option>
                                                                    <option value="9:30">09:30 AM</option>
                                                                    <option value="10:0">10:00 AM</option>
                                                                    <option value="10:30">10:30 AM</option>
                                                                    <option value="11:0">11:00 AM</option>
                                                                    <option value="11:30">11:30 AM</option>
                                                                    <option value="12:0">12:00 PM</option>
                                                                    <option value="12:30">12:30 PM</option>
                                                                    <option value="13:0">01:00 PM</option>
                                                                    <option value="13:30">01:30 PM</option>
                                                                    <option value="14:0">02:00 PM</option>
                                                                    <option value="14:30">02:30 PM</option>
                                                                    <option value="15:0">03:00 PM</option>
                                                                    <option value="15:30">03:30 PM</option>
                                                                    <option value="16:0">04:00 PM</option>
                                                                    <option value="16:30">04:30 PM</option>
                                                                    <option value="17:0">05:00 PM</option>
                                                                    <option value="17:30">05:30 PM</option>
                                                                    <option value="18:0">06:00 PM</option>
                                                                    <option value="18:30">06:30 PM</option>
                                                                    <option value="19:0">07:00 PM</option>
                                                                    <option value="19:30">07:30 PM</option>
                                                                    <option value="20:0">08:00 PM</option>
                                                                    <option value="20:30">08:30 PM</option>
                                                                    <option value="21:0">09:00 PM</option>
                                                                    <option value="21:30">09:30 PM</option>
                                                                    <option value="22:0">10:00 PM</option>
                                                                    <option value="22:30">10:30 PM</option>
                                                                    <option value="23:0">11:00 PM</option>
                                                                    <option value="23:30">11:30 PM</option>
                                                                </select>

                                                                <select onChange={(e) => {var newArr = specialDaysValues; newArr[elem].close = e.target.value}} className="time-picker">
                                                                <option value="0:0">12:00 AM</option>
                                                                    <option value="0:30">12:30 AM</option>
                                                                    <option value="1:0">01:00 AM</option>
                                                                    <option value="1:30">01:30 AM</option>
                                                                    <option value="2:0">02:00 AM</option>
                                                                    <option value="2:30">02:30 AM</option>
                                                                    <option value="3:0">03:00 AM</option>
                                                                    <option value="3:30">03:30 AM</option>
                                                                    <option value="4:0">04:00 AM</option>
                                                                    <option value="4:30">04:30 AM</option>
                                                                    <option value="5:0">05:00 AM</option>
                                                                    <option value="5:30">05:30 AM</option>
                                                                    <option value="6:0">06:00 AM</option>
                                                                    <option value="6:30">06:30 AM</option>
                                                                    <option value="7:0">07:00 AM</option>
                                                                    <option value="7:30">07:30 AM</option>
                                                                    <option value="8:0">08:00 AM</option>
                                                                    <option value="8:30">08:30 AM</option>
                                                                    <option value="9:0">09:00 AM</option>
                                                                    <option value="9:30">09:30 AM</option>
                                                                    <option value="10:0">10:00 AM</option>
                                                                    <option value="10:30">10:30 AM</option>
                                                                    <option value="11:0">11:00 AM</option>
                                                                    <option value="11:30">11:30 AM</option>
                                                                    <option value="12:0">12:00 PM</option>
                                                                    <option value="12:30">12:30 PM</option>
                                                                    <option value="13:0">01:00 PM</option>
                                                                    <option value="13:30">01:30 PM</option>
                                                                    <option value="14:0">02:00 PM</option>
                                                                    <option value="14:30">02:30 PM</option>
                                                                    <option value="15:0">03:00 PM</option>
                                                                    <option value="15:30">03:30 PM</option>
                                                                    <option value="16:0">04:00 PM</option>
                                                                    <option value="16:30">04:30 PM</option>
                                                                    <option value="17:0">05:00 PM</option>
                                                                    <option value="17:30">05:30 PM</option>
                                                                    <option value="18:0">06:00 PM</option>
                                                                    <option value="18:30">06:30 PM</option>
                                                                    <option value="19:0">07:00 PM</option>
                                                                    <option value="19:30">07:30 PM</option>
                                                                    <option value="20:0">08:00 PM</option>
                                                                    <option value="20:30">08:30 PM</option>
                                                                    <option value="21:0">09:00 PM</option>
                                                                    <option value="21:30">09:30 PM</option>
                                                                    <option value="22:0">10:00 PM</option>
                                                                    <option value="22:30">10:30 PM</option>
                                                                    <option value="23:0">11:00 PM</option>
                                                                    <option value="23:30">11:30 PM</option>
                                                                </select>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button style={{ backgroundColor: "#5096FF"}} onClick={() => {saveSpecialHours()}} variant="primary">
                                                Save Hours
                                            </Button>

                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                    </>
                                ) : null
                            }

                            {
                                page === "Account" ? (
                                    <>

                                    <div className="Info-div">

                                        <h2>My Account</h2>


                                        <div className="Form-Input">
                                            <label>Email</label>
                                            <input disabled className="input-field" value={account}></input>
                                        </div>

                                        <div className="Form-Input">
                                            <label>Password</label>
                                            <input type="password" disabled className="input-field" value={"123123123$"}></input>
                                        </div>

                                        <div style={{marginTop:10}} className="Form-Input">
                                            <label></label>
                    
                                            <a href="#" onClick={() => {
                                                sendPasswordResetEmail(auth, account)
                                                .then(function () {
                                                    alert.show(`Password reset email sent to: ${account}`)
                                                }).catch(function (e) {
                                                    alert.show(e.toString())
                                                })
                                            }}>Reset Password</a>
                                        </div>
                                        

                                        <div style={{justifyContent:"flex-start"}}  className="Form-Input">
                                                <button onClick={() => {
                                                    let token = localStorage.getItem("@token");
                                                    let retailerId = localStorage.getItem("@retailer");
                                                    if (!token || !retailerId) {
                                                        return window.location.href = "/login";
                                                    }
                                                    window.open(`${hostname}/api/retailer/${retailerId}/sub/subscription`, "_blank")
                                                }} style={{borderRadius:100, height:40, color:"#fff", border:"none", backgroundColor:"#5096FF", paddingLeft:35, paddingRight:35}} className="">Manage Subscription</button>

                                        </div>


                                    </div>

                                    {
                                        accountRole === "admin" ? (
                                            <div className="Info-div">
                                                <h2>User Accounts</h2>

                                                <Modal className="Create-user-modal" dialogClassName="modal-90w" fullscreen={"sm-down"} show={show} onHide={handleClose} animation={true}>
                                                    <Modal.Header closeButton>
                                                    <Modal.Title>Invite User</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div style={{marginTop:0}}>
                                                            <label style={{marginBottom:15}}>Email</label>
                                                            <input style={{width:"100%"}} placeholder="john@heed.chat" onChange={(e) => {setAccountEmail(e.target.value)}} value={accountEmail}></input>
                                                        </div>
                                                        <div style={{marginTop:30}}>
                                                            <label style={{marginBottom:15}}>Name</label>
                                                            <input style={{width:"100%"}} placeholder="John Doe" onChange={(e) => {setAccountName(e.target.value)}} value={accountName}></input>
                                                        </div>
                                                        <div style={{marginTop:30}} className="">
                                                            <label style={{marginBottom:15}}>Locations</label>  
                                                            <select multiple value={accountLocation} 
                                                            
                                                            onChange={(e) => {
                                                                var values = [];
                                                                var options = e.target.options;
                                                                for (var i = 0; i < options.length;  i++) {
                                                                    if (options[i].selected) {
                                                                        values.push(options[i].value);
                                                                    }
                                                                }
                                                                setAccountLocation(values);
                                                            }}
                                                           >
                                                                <option value="61239787a61dca00a274ac5f">Neat Cannabis Komoka</option>
                                                                <option value="5f46acf986efa201151435a3">Neat Cannabis Oxford</option>
                                                            </select>

                                                        </div>
                                                        <div style={{marginTop:30}} className="">
                                                            <label style={{marginBottom:15}}>Admin</label>  
                                                            <select value={newAccountRole} onChange={(e)=> {setNewAccountRole(e.target.value)}}>
                                                                <option value="admin">Yes</option>
                                                                <option value="user">No</option>                                                                
                                                            </select>

                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                    <Button style={{ backgroundColor: "#5096FF"}} onClick={() => {
                                                        // Submit form to create user
                                                        let token = localStorage.getItem("@token");
                                                        let retailerId = localStorage.getItem("@retailer");
                                                        if (!token || !retailerId) {
                                                            return window.location.href = "/login";
                                                        }
                                                        if (!accountEmail || !accountName || !accountRole || !accountLocation || accountLocation.length === 0) {
                                                            handleClose();
                                                            alert.show("Please fill in all fields!");
                                                            return;
                                                        }
               
                                                        // POST TO DB
                                                        fetch( `${hostname}/api/retailer/${retailerId}/accounts`, { headers: {  'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({
                                                            email: accountEmail,
                                                            name: accountName,
                                                            retailers: accountLocation,
                                                            role: newAccountRole
                                                        }), crossDomain:true })
                                                        .then((response) => response.json())
                                                        .then(async (response) => {
                                                            if (response.success) {
                                                                handleClose();
                                                                alert.show("Added new user!");
                                                                fetchRetailer();
                                                            } else {
                                                                if (response.error == "not logged in") {
                                                                    localStorage.removeItem("@token");
                                                                    window.location.href = "/login";
                                                                }
                                                                handleClose();
                                                                alert.show(response.error);
                                                            }
                                                        })
                                                        .catch((err) => {console.log(err);
                                                        });

                                            

                                                    
                                   
                                                    }} variant="primary">
                                                        Create User
                                                    </Button>

                                                    </Modal.Footer>
                                                </Modal>
        
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Role</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {accounts.map((retailerAccount => {
                                                            return (
                                                                <>
                                                                    {retailerAccount.email != account ? (
                                                                        <tr>
                                                                            <td>{retailerAccount.name}</td>
                                                                            <td>{retailerAccount.email}</td>
                                                                            <td>{retailerAccount.role}</td>
                                                                        </tr>
                                                                    ) : null}
                                                                </>
                                                            )
                                                        }))}
                                                    
                                                    </tbody>
                                                </table>
                                                <div style={{justifyContent:"flex-start"}}  className="Form-Input">
                                                    <button onClick={() => {
                                                        handleShow()
                                                    }} 
                                                    style={{borderRadius:100, height:40, color:"#fff", border:"none", backgroundColor:"#5096FF", paddingLeft:35, paddingRight:35}} className="">
                                                        Create User
                                                    </button>
        
                                                </div>
                                            </div>
                                        ) : null
                                    }


                                    </>
                                ) : null
                            }

                            {
                                page === "Customize" ? (
                                    <>


                                    <div className="store-front Info-div">
                                        <h2>Store Front Customizations</h2>

                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Title</label>
                                            <input value={title} onChange={((e) => {setTitle(e.target.value)})}></input>
                                        </div>


                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Quantity Threshold</label>
                                            <input onChange={(e) => {setQuantityThreshold(e.target.value)}} value={quantityThreshold} type="number"></input>
                                            <p className="small-helper">Minimum quantity required to show on shopping cart.</p>
                                        </div>


                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Products to Upsell</label>
                                            {/* <Multiselect
                                                placeholder="Search for Product(s)"
                                                style={{marginTop:15}}
                                                options={productsMultiSelect} 
                                                selectedValues={upsells}
                                                onSelect={(e) => {
                                                    setUpsells(e);
                                                }}
                                                onRemove={(e) => {
                                                    setUpsells(e);
                                                }}
                                                displayValue="name"
                                            /> */}

                                            <Select
                                                style={{marginTop:15}}
                                                value={upsells}
                                                onChange={(e => {
                                                    // setUpsells(e);
                                                    setUpsells(e);
                                                })}
                                                options={productsMultiSelect}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                                />

                                            <p className="small-helper">Select which products you want to be upsold in the cart to your customers, if no products are selected we will recommend similar products to your customers</p>
                                        </div>

                                        <div className="Form-Input">
                                            <button onClick={() => {
                                                saveCustomizations();
                                            }} className="Save">
                                                <FontAwesomeIcon style={{}} icon={faFloppyDisk} />
                                            </button>
                                        </div>
                            
                                    </div>

                                    


                                    <div className="store-front Info-div">
                                        <h2>Shelves</h2>
                                        <div className="Form-Input">
                                            <div style={{minWidth:"100%", marginTop:0, marginBottom:15}} className="helper">
                                                <p>Create custom shelves for your store front, then select which products you want to go into the section</p>
                                            </div>
                                            {
                                                catalogueAdded ? (
                                                    null
                                                ) : (
                                                    <Draggable onPosChange={(currentPos, newPos) => {reArrangeCatalogues(currentPos, newPos)}}>
                                                        {
                                                            catalogue.map(((shelve, index) => {
                                                                return (
                                                                    <Catalogue fetchRetailer={fetchRetailer} index={index} data={shelve} productsMultiSelect={productsMultiSelect}/>                                          
                                                                )
                                                            }))
                                                        }
                                                    </Draggable>
                                                )
                                            }
                                            
                                        </div>


                                        <div style={{display:"flex", flexDirection:"row"}} className="Form-Input">
                                            <button onClick={() => {
                                                var newCatalogue = catalogue;
                                                newCatalogue.push({title: `Shelf #${catalogue.length+1}`, products: []});
                                                setCatalogue(newCatalogue);
                                                setCatalogueAdded(true);
                                            }} className="Save">

                                                <FontAwesomeIcon style={{}} icon={faPlus} />
                                            </button>



                                            <button style={{marginLeft:15}} onClick={() => {
                                                saveCataloguePositions();
                                            }} className="Save">
                                                <FontAwesomeIcon style={{}} icon={faFloppyDisk} />
                                            </button>

                                            
                                        </div>

                                </div>


                                <div className="store-front Info-div">
                                        <h2>Branding Customizations</h2>

                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Background Color</label>

                                            <ChromePicker
                                                color={ backgroundColor }
                                                onChangeComplete={ (e) => {
                                                    console.log(e.hex);
                                                    setBackgroundColor(e.hex);
                                                } }
                                            />

                                        </div>

                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Text Color</label>
                                            <ChromePicker 
                                                color={ textColor }
                                                onChangeComplete={ (e) => {
                                                    setTextColor(e.hex);
                                                } }
                                            />
                                        </div>

                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Secondary Text Color</label>
                                            <ChromePicker 
                                                color={ secondaryTextColor }
                                                onChangeComplete={ (e) => {
                                                    setSecondaryTextColor(e.hex);
                                                } }
                                            />
                                        </div>

                                        <div className="Form-Input">
                                            <label style={{marginBottom:15}}>Accent Color</label>
                                            <ChromePicker 
                                                color={ accentColor }
                                                onChangeComplete={ (e) => {
                                                    setAccentColor(e.hex);
                                                } }
                                            />
                                        </div>


                                        <div style={{justifyContent:"flex-start"}} className="Form-Input">
                                            <button onClick={() => {
                                                saveBranding();
                                            }} className="Save">
                                                <FontAwesomeIcon style={{}} icon={faFloppyDisk} />
                                            </button>
                                        </div>

                                    </div>

                                

                                </>
                                ) : null
                            }

                            {
                                page === "Deploy" ? (
                                    <div className="Info-div">

                                        <h2>Deploy</h2>

                                        <div style={{flexDirection:'column', alignItems:'flex-start'}} className="Form-Input">
                                            <label style={{marginBottom:15}}>Menu Embed</label>
                                            <textarea style={{ resize: 'none'}} readonly value={
                                            `<iframe src="https://shop.heed.chat/${localStorage.getItem("@retailer")}" width="100%" height="100%" frameborder="0" allowtransparency="true" allowfullscreen="true"></iframe>`}>
                                            </textarea>
                                        </div>

                                        <div style={{flexDirection:'column', alignItems:'flex-start'}} className="Form-Input">
                                            <button onClick={() => {
                                                navigator.clipboard.writeText(`<iframe src="https://shop.heed.chat/${localStorage.getItem("@retailer")}" width="100%" height="100%" frameborder="0" allowtransparency="true" allowfullscreen="true"></iframe>`)
                                                alert.show("Copied embed code to clipboard!")
                                            }} className="Save">
                                                <FontAwesomeIcon style={{}} icon={faClipboard} />
                                            </button>
                                        </div>


                                        {/* <div style={{flexDirection:'column', alignItems:'flex-start'}} className="Form-Input">
                                            <label style={{marginBottom:15}}>Widget Embed</label>
                                            <textarea readonly style={{ resize: 'none'}} value={
                                            `<heed-main store-view="one" color="#f4d29a" open="true" id="heed-dom" retailer="5f46acf986efa201151435a3"></heed-main><script src="https://platform.heed.chat/heed/iframe.js"></script>`
                                            }>
                                            </textarea>
                                        </div> */}

                                        {/* <div style={{flexDirection:'column', alignItems:'flex-start'}} className="Form-Input">
                                            <button onClick={() => {
                                                navigator.clipboard.writeText(`<heed-main store-view="one" color="#f4d29a" open="true" id="heed-dom" retailer="5f46acf986efa201151435a3"></heed-main><script src="https://platform.heed.chat/heed/iframe.js"></script>`)
                                                alert.show("Copied embed code to clipboard!")
                                            }} className="Save">Copy</button>
                                        </div> */}

                                    </div>
                                ) : null
                            }
                        
                        </>
                    ) : (
                        <CircleLoader></CircleLoader>
                    )
                }

                

            </div>

        </div>
    );
}

export default Settings;