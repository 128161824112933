import React, { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Orders from "./Pages/Orders";
import Menu from "./Pages/Menu";
import Customers from "./Pages/Customers";
import Settings from "./Pages/Settings";
import Login from "./Pages/Login";
import Location from "./Pages/Location";
import Analytics from "./Pages/Analytics";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  return (
    <Router>
        <Routes>
         <Route path="/" element={<Analytics />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/location" element={<Location />} />

          <Route path="/login" element={<Login />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
    </Router>
  );
}

