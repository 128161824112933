import React, { useEffect, useState, useRef } from "react";
import { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail } from "../firebase";
import { useAlert } from 'react-alert'
import "../Components/Login.css";

function Login({}) {
    // const [retailerId, setRetailerId] = useState(process.env.NODE_ENV !== 'production' ? "testing" : "61239787a61dca00a274ac5f");
    const [retailerId, setRetailerId] = useState("");
    const [retailers, setRetailers] = useState([]);
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const alert = useAlert();

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let userId = localStorage.getItem("@userId");
        if (!token || !userId) {
            return window.location.href = "/login";
        }

                  // Pull retailers from API
                  fetch(`${hostname}/api/user/${userId}/locations` , { headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
                  .then((response) => response.json())
                  .then(async (response) => {
                      if (response.success) {
      
                          if (response.data.length > 1) {
                              setRetailerId(response.data[0].id);
                              setRetailers(response.data);
                          } else if (response.data.length == 1) {
                            localStorage.setItem("@retailer", response.data[0].id);
                            localStorage.setItem("@location", response.data[0].retailer);
                            return window.location.href = "/";
      
                          } else {
                              return alert.show("No account found" , {
                                  timeout: 0,
                                  type: 'error'
                              });
                          }
      
                      } else {
                          return alert.show(response.error , {
                              timeout: 0,
                              type: 'error'
                          });
                      }
                  })
                  .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      return alert.show(errorMessage , {
                          timeout: 0,
                          type: 'error'
                      });
                  }); 


    }, []);



    const selectLocation = () => {
        let retailer = retailers.find(retailer => retailer.id == retailerId);

        localStorage.setItem("@retailer", retailerId);
        localStorage.setItem("@location", retailer.retailer);
        localStorage.setItem("@image", retailer.logo);

        window.location.href = "/";
    }


    return (
        <div className="App Login">
            <div>
                <h1>Select Location</h1>
                <select value={retailerId} onChange={(e) => {setRetailerId(e.target.value)}} style={{marginTop:15}}>
                    {retailers.map((retailer, index) => {
                        return <option key={index} value={retailer.id}>{retailer.retailer}</option>
                    })}
                    {/* {
                        process.env.NODE_ENV !== 'production' ? (
                            <option value="testing">Demo Account</option>
                        ) : (
                            <>
                            <option value="61239787a61dca00a274ac5f">Neat Cannabis Komoka</option>
                            <option value="5f46acf986efa201151435a3">Neat Cannabis Oxford</option>
                            </>
                        )
                    } */}

                </select>
                <button onClick={() => {selectLocation()}}>Enter</button>
            </div>
        </div>
    );
}

export default Login;