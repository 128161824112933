import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, ListGroup, Container} from 'react-bootstrap';
import heedIcon from "../heed.png";
import MaterialTable from "material-table";
import blueIcon from "../blue_logo.png";
import retailerIcon from "../retailer.jpeg";
import CircleLoader from "../Components/CircleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faFloppyDisk, faCaretLeft, faChartSimple, faBoxesStacked, faUser, faBars, faGear } from '@fortawesome/free-solid-svg-icons'
import { useAlert } from 'react-alert'

function Menu({}) {
    const alert = useAlert()
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const [products, setProducts] = useState([]);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [openProduct, setOpenProduct] = useState(null);
    const [subcategory, setSubcategory] = useState("");

    const subcategories = {
        "": "N/A",
        
        "singles": "Singles",
        "pre-roll packs": "Pre-Roll Packs",
        "infused pre-rolls": "Infused Pre-Rolls",

        "cartridges": "Cartridges",
        "pods": "Pods",
        "disposables": "Disposables",
        "live resin": "Live Resin",
        "rosin": "Rosin",
        "kief": "Kief",
        "applicators": "Applicators",
        "sauce": "Sauce",
        "diamonds": "Diamonds",

        "chocolates": "Chocolates",
        "gummies": "Gummies",
        "baked goods": "Baked Goods",
        "drinks": "Drinks",
        "capsules": "Capsules",
        "chews": "Chews",
        "hard candy": "Hard Candy",
        "sublingual products": "Sublingual Products",
        "savory snacks": "Savory Snacks",
        "dissolvables": "Dissolvables",
        "lozenges": "Lozenges",

        "topical oils":"Topical Oils",
        "lotions": "Lotions",
        "roll-ons": "Roll-ons",
        "bath products": "Bath Products",

        "batteries": "Batteries",
        "devices": "Devices",
        "papers": "Papers",
        "grinders": "Grinders",
        "glassware": "Glassware",
        "trays": "Trays",
        "dab tools":"Dab Tools"
    }


    useEffect(() => {
        fetchProducts();
        setTimeout(() => {
            window.location.reload();
        }, 1800000);
    }, []);

    const fetchProducts = () => {
        let retailerId = localStorage.getItem("@retailer");
        if (!retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/menu/${retailerId}/products`, {  headers: { 'Content-Type': 'application/json' }, method: "POST", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                setProducts(response.data);
                setProductsLoaded(true);
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err)});  
    }


    const updateSubcategories = (body) => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/menu/${retailerId}/products/update`, {  headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "POST", body: JSON.stringify({products: body}), crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                alert.show("Updated subcategory!");
                setProductsLoaded(false);
                fetchProducts();
                // setOpenProduct(null);
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err)});  
    }

    useEffect(() => {
        if (openProduct!==null) {
            console.log(openProduct);
            setSubcategory(openProduct.subcategory);
        }
    }, [openProduct])

    return (
        <div className="App">
        <Navbar className="Mobile-Nav navbar-light" expand="lg">
        <Container>
            <Navbar.Brand href="#">
                <img src={blueIcon} width="50" height="50" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link href="/">Analytics</Nav.Link>
                    <Nav.Link href="/orders">Orders</Nav.Link>
                    <Nav.Link href="/customers">Customers</Nav.Link>
                    <Nav.Link href="/menu">Menu</Nav.Link>
                    <Nav.Link href="/settings">Settings</Nav.Link>
                    <Nav.Link href="#">Support</Nav.Link>
                    <Nav.Link onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} href="#">Logout</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <div className='Sidebar'>

        {
            localStorage.getItem("@image") ? 
            (
              <img className="Retailer" src={localStorage.getItem("@image")}></img>
            ) : null
          }
        {localStorage.getItem("@location") ? ( 
          <h5 style={{fontSize:12, fontWeight:"bold", marginTop:15}}>{localStorage.getItem("@location")}</h5>
        ) : null}
        
        <div className="Sidebar-Middle">
             <ListGroup as="ul">
                 <ListGroup.Item onClick={() => {window.location.href = '/'}} as="li" style={{cursor: 'pointer'}} >
                   <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faChartSimple} />
                    Analytics
                </ListGroup.Item>

                <ListGroup.Item onClick={() => {window.location.href = '/orders'}} as="li" style={{cursor: 'pointer'}} >
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBoxesStacked} />
                    Orders
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/customers'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faUser} />
                    Customers
                </ListGroup.Item>
                <ListGroup.Item active onClick={() => {window.location.href = '/menu'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBars} />
                    Menu
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faGear} />
                    Settings
                </ListGroup.Item>
            </ListGroup>
        </div>

        <div className="Sidebar-Bottom">
            
            <div onClick={() => {window.location.href = "/location"}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
              <FontAwesomeIcon style={{cursor:"pointer", fontSize:36, color:"#8bb8fc"}} icon={faDoorOpen} />
              <p style={{color:"#8bb8fc", marginTop:5}}>Switch Location</p>
            </div>
            {/* <img className='Logo' src={heedIcon}></img> */}

            <div>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Support
                </ListGroup.Item>
        
                <ListGroup.Item onClick={() => {
                    localStorage.removeItem("@token");
                    localStorage.removeItem("@user");
                    window.location.href = "/login";
                }} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Logout
               </ListGroup.Item>
            </div>

        </div>



        </div>


            {
                openProduct === null ? (
                    <div className="App-Body">
                        <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                            <h1>All Products</h1>
                        </div>


                        <div id="menu-table-container">
    
                            {
                                productsLoaded ? (
                                    <MaterialTable
                                        options={{
                                            exportButton: false,
                                            sorting: true,
                                            selection: false,
                                            filtering:true,
                                            exportAllData: true,
                                            pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100, 150]
                                        }}
                                        columns={[
                                            {editable:false, filtering: false, title: "Product", field: "Name",
                                                render: (rowData) => {
                                                    return (
                                                        <a onClick={() => {setOpenProduct(rowData)}} href="#">{rowData.Name}</a>
                                                    )            
                                                }
                                            },
                                            {editable:false, filtering: false, title: "Brand", field: "brandName"},
                                            {editable:false, filterPlaceholder: 'Filters', editable:false, filtering: true, title: "Category", field: "type",  lookup: {Flower: 'Flower', "Pre-Rolls": "Pre-Rolls", Edible: "Edible", Concentrate: "Concentrate", Vaporizers: "Vaporizers", Topicals: "Topicals" , Accessories: "Accessories"}},
                                            {filterPlaceholder: 'Filters', filtering: true, title: "Subcategory", field: "subcategory",  lookup: subcategories},
                                            {filterPlaceholder: 'Filters', filtering: true, title: "Type", field:"strainType", lookup: {Hybrid: "Hybrid", Indica: "Indica", Sativa: "Sativa", Blend: "Blend"} },
                                            {editable:false, filtering: false, title: "Price",  render: (rowData) => `$${rowData.Prices[0]}` }
                                        ]}
                                        title={`Products (${products.length})`}
                                        data={products}
                                        editable={{
                                            isEditable: rowData => true,
                                            onBulkUpdate: changes => 
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        console.log(changes);
                                                        var body = [];
                                                        for (let [key, value] of Object.entries(changes)) {
                                                            value = value.newData;
                                                            let id = value._id;
                                                            let subcategory = value.subcategory;
                                                            body.push({id: id, subcategory: subcategory});
                                                        }
                                                        
                                                        updateSubcategories(body);
        
                                                        // setProducts([...data, newData]);
                                    
                                                        resolve();
                                                    }, 1000);
                                                }),
                                            onRowUpdateCancelled: rowData => console.log('Row editing cancelled'),
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        var body = [{id: newData._id, subcategory: newData.subcategory}];
                                                        console.log(body);
                                                        updateSubcategories(body);
                                                        resolve();
                                                    }, 1000);
                                                })
                                        }}
                                    />
                                ) : (
                                    <CircleLoader></CircleLoader>
                                )
                            }
                        </div>

                    </div>
                ) : (
                    <div className="App-Body">
                        <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                            <h1 style={{cursor:"pointer", color:"#5096FF"}} onClick={() => {setOpenProduct(null)}}>
                                <FontAwesomeIcon style={{marginRight:5}} icon={faCaretLeft} />
                                Back to all products</h1>
                            {/* <a href="#" onClick={() => {setOpenProduct(null)}} style={{margin:"auto"}}> {"< "} Back to all products</a> */}
                        </div>


                        <div className="Info-div">
                            <h2>Edit Menu Product</h2>

                            <div style={{marginBottom:15}} className="helper">
                                <p>You're able to configure subcategory for products. For the other fields please update them via Cova.</p>
                            </div>


                            <div className="Form-Input">
                                {/* <label>Image</label> */}
                                <img style={{with:160, height:160, margin:"auto"}} src={openProduct.Image}></img>
                            </div>
        
                            <div className="Form-Input">
                                <label>Name</label>
                                <input className="input-field" value={openProduct.Name} disabled></input>
                            </div>


                            <div className="Form-Input">
                                <label>Category</label>
                                <input className="input-field" value={openProduct.type} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Subcategory</label>
                                <select onChange={(e) => {setSubcategory(e.target.value)}} value={subcategory} className="input-field">
                                {
                                    Object.keys(subcategories).map((key, index) => {
                                        return (
                                            <option selected={key == openProduct.subcategory} value={key}>{subcategories[key]}</option>
                                        )                                        
                                    })
                                }
                                </select>
                                {/* <input className="input-field" value={openProduct.subcategory} disabled></input> */}
                            </div>

                            <div className="Form-Input">
                                <label>Brand</label>
                                <input className="input-field" value={openProduct.brandName} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Rec Price</label>
                                <input className="input-field" value={`$${openProduct.Prices[0].toFixed(2)}`} disabled></input>
                            </div>

                            <div className="Form-Input">
                                <label>Description</label>
                                <textarea disabled className="text-area-input" value={openProduct.description}>{openProduct.description}</textarea>
                            </div>

                            <div style={{justifyContent:"flex-start"}} className="Form-Input">
                                <button onClick={() => {
                                       var body = [{id: openProduct._id, subcategory: subcategory}];
                                       updateSubcategories(body);
                                }} className="Save">
                                    <FontAwesomeIcon icon={faFloppyDisk} />
                                </button>
                            </div>
  
                     </div>

    

                    </div>
                )
            }
            

        </div>
    );
}

export default Menu;