import React, { useEffect, useState, useRef } from "react";
import { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail } from "../firebase";
import { useAlert } from 'react-alert'
import "../Components/Login.css";

function Login({}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectStores, setSelectStores] = useState(false);
    const alert = useAlert();
    const [token, setToken] = useState("");
    // const [retailerId, setRetailerId] = useState(process.env.NODE_ENV !== 'production' ? "testing" : "61239787a61dca00a274ac5f");
    const [retailerId, setRetailerId] = useState("");
    const [retailers, setRetailers] = useState([]);

    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let userId = localStorage.getItem("@userId");
        if (token && userId) {
            window.location.href = "/";
        }            
    }, []);

    const login = () => {
        if (!email || !password) {
            alert.show('Please fill in all fields' , {
                timeout: 0,
                type: 'error'
            });
        }
        signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            if (!user.emailVerified) {
                // return alert.show('Please verify your email' , {
                //     timeout: 0,
                //     type: 'error'
                // });
            }

            // Save token
            setToken(user.accessToken);
            localStorage.setItem("@userId", user.uid);

            // Pull retailers from API
            fetch(`${hostname}/api/user/${user.uid}/locations` , { headers: {'Authorization': `Bearer ${user.accessToken}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
            .then((response) => response.json())
            .then(async (response) => {
                if (response.success) {

                    if (response.data.length > 1) {
                        setRetailerId(response.data[0].id);
                        setRetailers(response.data);
                        return setSelectStores(true);
                    } else if (response.data.length == 1) {

                        localStorage.setItem("@token", user.accessToken);
                        localStorage.setItem("@user", email);
                        localStorage.setItem("@retailer", response.data[0].id);
                        localStorage.setItem("@location", response.data[0].retailer);
                        localStorage.setItem("@image", response.data[0].logo);
                        return window.location.href = "/";

                    } else {
                        return alert.show("No account found" , {
                            timeout: 0,
                            type: 'error'
                        });
                    }

                } else {
                    return alert.show(response.error , {
                        timeout: 0,
                        type: 'error'
                    });
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return alert.show(errorMessage , {
                    timeout: 0,
                    type: 'error'
                });
            });  

            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return alert.show(errorMessage , {
                timeout: 0,
                type: 'error'
            });
            // ..
        });
    }


    const selectLocation = () => {
        let retailer = retailers.find(retailer => retailer.id == retailerId);
        console.log(retailer);
        localStorage.setItem("@token", token);
        localStorage.setItem("@user", email);
        localStorage.setItem("@retailer", retailerId);
        localStorage.setItem("@location", retailer.retailer);
        localStorage.setItem("@image", retailer.logo);

        window.location.href = "/";
    }


    return (
        <div className="App Login">


            {
                selectStores ? (
                    <div>
                        <h1>Select Location</h1>
                        <select value={retailerId} onChange={(e) => {setRetailerId(e.target.value)}} style={{marginTop:15}}>
                            {retailers.map((retailer => {
                                return <option value={retailer.id}>{retailer.retailer}</option>
                            }))}
                        </select>
                        <button onClick={() => {selectLocation()}}>Enter</button>
                    </div>
                ) : (
                    <div>
                    <h1>Login</h1>
                    <input style={{marginTop:15}} value={email} type="email" onChange={((e) => {setEmail(e.target.value)})} placeholder="Email"></input>
                    <input value={password} type="password" onChange={((e) => {setPassword(e.target.value)})} placeholder="Password"></input>
                    <a href="#" onClick={() => {
                        if (!email) {
                            return alert.show("Please enter a valid email!" , {
                                timeout: 0,
                                type: 'error'
                            });
                        }

                        sendPasswordResetEmail(auth, email)
                        .then(function () {
                            alert.show(`Password reset email sent!`);
                        }).catch(function (e) {
                            alert.show(e.toString() , {
                                timeout: 0,
                                type: 'error'
                            });
                        })

                        
                    }} style={{alignSelf:"flex-start", marginTop:15, marginBottom:5, fontSize:14, color: "#5096FF"}}>Reset password</a>
                    <button onClick={() => {login()}} >Login</button>
                </div>
                )
            }

        </div>
    );
}

export default Login;