import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, ListGroup, Container} from 'react-bootstrap';
import heedIcon from "../heed.png";
import MaterialTable from "material-table";
import blueIcon from "../blue_logo.png";
import retailerIcon from "../retailer.jpeg";
import CircleLoader from "../Components/CircleLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faChartSimple, faBoxesStacked, faUser, faBars, faGear } from '@fortawesome/free-solid-svg-icons'

function Customers({}) {
    // const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let retailerId = localStorage.getItem("@retailer");
        if (!token || !retailerId) {
            return window.location.href = "/login";
        }
        fetch( `${hostname}/api/customer/${retailerId}`, {    headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                setCustomers(response.data);
                setLoading(false);
            } else {
                if (response.error == "not logged in") {
                    localStorage.removeItem("@token");
                    window.location.href = "/login";
                }
            }
        })
        .catch((err) => {console.log(err)});

        setTimeout(() => {
            window.location.reload();
        }, 1800000);

    }, []);



    return (
        <div className="App">

<Navbar className="Mobile-Nav navbar-light" expand="lg">
        <Container>
            <Navbar.Brand href="#">
                <img src={blueIcon} width="50" height="50" className="d-inline-block align-top" alt="" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                    <Nav.Link href="/">Analytics</Nav.Link>
                    <Nav.Link href="/orders">Orders</Nav.Link>
                    <Nav.Link href="/customers">Customers</Nav.Link>
                    <Nav.Link href="/menu">Menu</Nav.Link>
                    <Nav.Link href="/settings">Settings</Nav.Link>
                    <Nav.Link href="#">Support</Nav.Link>
                    <Nav.Link onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} href="#">Logout</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <div className='Sidebar'>

        {
            localStorage.getItem("@image") ? 
            (
              <img className="Retailer" src={localStorage.getItem("@image")}></img>
            ) : null
        }
        {localStorage.getItem("@location") ? ( 
          <h5 style={{fontSize:12, fontWeight:"bold", marginTop:15}}>{localStorage.getItem("@location")}</h5>
        ) : null}
        
        <div className="Sidebar-Middle">
             <ListGroup as="ul">
                 <ListGroup.Item onClick={() => {window.location.href = '/'}} as="li" style={{cursor: 'pointer'}} >
                   <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faChartSimple} />
                    Analytics
                </ListGroup.Item>

                <ListGroup.Item onClick={() => {window.location.href = '/orders'}} as="li" style={{cursor: 'pointer'}} >
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBoxesStacked} />
                    Orders
                </ListGroup.Item>
                <ListGroup.Item active onClick={() => {window.location.href = '/customers'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faUser} />
                    Customers
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/menu'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faBars} />
                    Menu
                </ListGroup.Item>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{cursor: 'pointer'}}>
                  <FontAwesomeIcon style={{marginLeft:-5, marginRight:10}} icon={faGear} />
                    Settings
                </ListGroup.Item>
            </ListGroup>
        </div>

        <div className="Sidebar-Bottom">
            <div onClick={() => {window.location.href = "/location"}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
              <FontAwesomeIcon style={{cursor:"pointer", fontSize:36, color:"#8bb8fc"}} icon={faDoorOpen} />
              <p style={{color:"#8bb8fc", marginTop:5}}>Switch Location</p>
            </div>
            {/* <img className='Logo' src={heedIcon}></img> */}

            <div>
                <ListGroup.Item onClick={() => {window.location.href = '/settings'}} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Support
                </ListGroup.Item>
        
                <ListGroup.Item onClick={() => {
                        localStorage.removeItem("@token");
                        localStorage.removeItem("@user");
                        window.location.href = "/login";
                    }} as="li" style={{fontSize:14, cursor: 'pointer'}}>
                    Logout
               </ListGroup.Item>
            </div>

        </div>



        </div>

            <div className="App-Body">
                <div style={{ paddingTop:50, paddingBottom:20, paddingLeft:60, paddingRight:60}}>
                    <h1>Customers</h1>
                </div>


                <div>
                    {
                        loading === true ? (
                            <CircleLoader></CircleLoader>
                        ) : (
                            <MaterialTable
                                options={{
                                    exportButton: true,
                                    sorting: true,
                                    selection: false,
                                    editable: false,
                                    filtering:false,
                                    exportAllData: true,
                                    pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100, 150]
                                }}
                                columns={[
                                    {title: "Name", field: 'PrimaryName', render: (rowData => rowData.PrimaryName + " " + rowData.FamilyName)},
                                    {title: "Date", field: 'LastModifiedDateUtc', render: (rowData) => new Date(Date.parse(rowData.LastModifiedDateUtc)).toLocaleDateString('en-US')},
                                    { title: "Type", field: 'Guest', render: (rowData) => "Guest"},
                                    {title: "Email", field: 'email', render: (rowData) => rowData.email},
                                    {title: "Phone", field: 'phone',  render: (rowData) => rowData.phone},
                                    { title: "Allow Marketing", field: 'DoNotContact',  render: (rowData) => rowData.DoNotContact ? "false" : "true"},
                                    { title: "Orders", field: 'totalOrders',  render: (rowData) => rowData.totalOrders},
                                    { title: "Spent", field: 'orderTotals',  render: (rowData) => `$${rowData.orderTotals}`}
                                ]}
                                data={customers}
                                title={`Customers (${customers.length})`}
                            />
                        )
                    }
                    </div>

            </div>

        </div>
    );
}

export default Customers;